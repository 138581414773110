// Look at ./NodeTrackingReports for a working example
import {Button, ButtonGroup} from '@mui/material';
import update from 'immutability-helper';
import isEmpty from 'lodash/isEmpty';
import {useMemo} from 'react';

import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {DashboardPanelActionsSlot} from '../DashboardPanelActionsSlot';
import {
  AMSLocationHistoryReport,
  AMSLocationHistoryReportData,
  getAMSLocationHistoryReportData,
} from './AMSLocationHistoryReport';
import {
  AMSLongTermHistoryReport,
  AMSLongTermHistoryReportData,
  getAMSLongTermHistoryReportData,
} from './AMSLongTermHistoryReport';
import {
  AMSShortTermHistoryReport,
  AMSShortTermHistoryReportData,
  getAMSShortTermHistoryReportData,
} from './AMSShortTermHistoryReport';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
}

export interface GasMonitoringReportsData {
  viewType: 'amsShortTerm' | 'amsLongTerm' | 'amsLocation';
  title: string;
  amsShortTerm: AMSShortTermHistoryReportData;
  amsLongTerm: AMSLongTermHistoryReportData;
  amsLocation: AMSLocationHistoryReportData;
}

const getGasMonitoringReportsData = (): GasMonitoringReportsData => ({
  viewType: 'amsShortTerm',
  title: 'Gas Monitoring Short Term History',
  amsShortTerm: getAMSShortTermHistoryReportData(),
  amsLongTerm: getAMSLongTermHistoryReportData(),
  amsLocation: getAMSLocationHistoryReportData(),
});

export const GasMonitoringReport = ({value, onUpdate}: Props) => {
  const config = useMemo(
    () =>
      !isEmpty(value)
        ? (value as GasMonitoringReportsData)
        : getGasMonitoringReportsData(),
    [value]
  );
  return (
    <>
      <DashboardPanelActionsSlot>
        <ButtonGroup sx={{minWidth: 410}}>
          <Button
            size="small"
            variant={
              config.viewType === 'amsShortTerm' ? 'contained' : 'outlined'
            }
            onClick={() => {
              onUpdate?.(
                update(config, {
                  viewType: {
                    $set: 'amsShortTerm',
                  },
                })
              );
            }}
          >
            Short Term
          </Button>
          <Button
            size="small"
            variant={
              config.viewType === 'amsLongTerm' ? 'contained' : 'outlined'
            }
            onClick={() => {
              onUpdate?.(
                update(config, {
                  viewType: {
                    $set: 'amsLongTerm',
                  },
                })
              );
            }}
          >
            Long Term
          </Button>
          <Button
            size="small"
            variant={
              config.viewType === 'amsLocation' ? 'contained' : 'outlined'
            }
            onClick={() => {
              onUpdate?.(
                update(config, {
                  viewType: {
                    $set: 'amsLocation',
                  },
                })
              );
            }}
          >
            Location
          </Button>
        </ButtonGroup>
      </DashboardPanelActionsSlot>

      {config.viewType === 'amsShortTerm' ? (
        <AMSShortTermHistoryReport
          value={config.amsShortTerm}
          onUpdate={(v) => {
            onUpdate?.(
              update(value ?? getAMSShortTermHistoryReportData(), {
                amsShortTerm: {
                  $set: v ?? getAMSShortTermHistoryReportData(),
                },
                amsLongTerm: {
                  $set: value?.amsLongTerm ?? getAMSLongTermHistoryReportData(),
                },
                amsLocation: {
                  $set: value?.amsLocation ?? getAMSLocationHistoryReportData(),
                },
                viewType: {
                  $set: 'amsShortTerm',
                },
              })
            );
          }}
        />
      ) : config.viewType === 'amsLongTerm' ? (
        <AMSLongTermHistoryReport
          value={config.amsLongTerm}
          onUpdate={(v) => {
            onUpdate?.(
              update(value ?? getAMSLongTermHistoryReportData(), {
                amsShortTerm: {
                  $set:
                    value?.amsShortTerm ?? getAMSShortTermHistoryReportData(),
                },
                amsLongTerm: {
                  $set: v ?? getAMSLongTermHistoryReportData(),
                },
                amsLocation: {
                  $set: value?.amsLocation ?? getAMSLocationHistoryReportData(),
                },
                viewType: {
                  $set: 'amsLongTerm',
                },
              })
            );
          }}
        />
      ) : config.viewType === 'amsLocation' ? (
        <AMSLocationHistoryReport
          value={config.amsLocation}
          onUpdate={(v) => {
            onUpdate?.(
              update(value ?? getAMSLocationHistoryReportData(), {
                amsShortTerm: {
                  $set:
                    value?.amsShortTerm ?? getAMSShortTermHistoryReportData(),
                },
                amsLongTerm: {
                  $set: value?.amsLongTerm ?? getAMSLongTermHistoryReportData(),
                },
                amsLocation: {
                  $set: v ?? getAMSLocationHistoryReportData(),
                },
                viewType: {
                  $set: 'amsLocation',
                },
              })
            );
          }}
        />
      ) : null}
    </>
  );
};
