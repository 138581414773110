import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import reduxSelectors from '../../../redux/selectors';
import { PIE_CHART_COLORS } from '../../../utils/colors';

interface Props {
  locationData: PieItem[] | [];
  sectionData: PieItem[] | [];
  location?: string;
  onChangeFilter: (v: string, filterType: string) => void;
}

export interface PieItem {
  name: string;
  value: number;
}

export const PieView = ({
  locationData,
  sectionData,
  location,
  onChangeFilter
}: Props) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);

  const customizedTooltip = ({ active, payload, data, isLocation }: { active?: boolean; payload?: any[]; data: PieItem[], isLocation: boolean}) => {
    if (active && payload && payload.length && data) {
      const zone = assets.zones.find((zone) => zone.name === payload[0].payload.name);
      const totalValue = data.reduce((acc: number, item: any) => acc + item.value, 0);
      return (
        <div style={{backgroundColor: '#fff', border: '2px solid #778844', padding: '0px 10px', borderRadius: '5px', color: '#000' }}>
          <p>{`${payload[0].name}`}</p>
          <p>{!isLocation && zone ? `ID: ${zone?.id}` : ''}</p>
          <p style={{fontSize: '14px'}}>#: {payload[0].payload.value}</p>
          <p>{!isLocation && (`%: ${(payload[0].payload.value / totalValue * 100).toFixed(2)}%`)}</p>
        </div>
      );
    }
    return null;
  };

  const renderCustomizedLocationLabel = ({ name, value, cx, cy, midAngle }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = 50;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="#fff" textAnchor="middle" dominantBaseline="central">
        {`${name} (${value})`}
      </text>
    );
  };

  const renderCustomizedSectionLabel = ({ name, value, cx, cy, midAngle }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = 180;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const label = `${name}: ${value}`;
    const maxLength = 20;
    const firstLine = label.length > maxLength ? label.slice(0, maxLength) : label;
    const secondLine = label.length > maxLength ? label.slice(maxLength) : '';

    return (
        <text
            x={x}
            y={y}
            fill="#fff"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize="14"
            fontWeight="bold"
        >
            <tspan x={x} dy="0">{firstLine}</tspan>
            {secondLine && <tspan x={x} dy="1.2em">{secondLine}</tspan>}
        </text>
    );
  };

  const handleSliceClick = useCallback((data: PieItem, isLocation: string) => {
    onChangeFilter(data.name, isLocation);
  }, [onChangeFilter]);

  return (
    <Box
      height="100%"
      width="100%"
    >
      <ResponsiveContainer className="pie_chart" minWidth={450}>
        {sectionData.length !== 0
          ? <PieChart>
              <Tooltip content={(props) => customizedTooltip({ ...props, data: sectionData, isLocation: !!location })} />
                <Pie
                  data={sectionData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius="0%"
                  outerRadius="70%"
                  fill="#82ca9d"
                  labelLine={false}
                  label={renderCustomizedSectionLabel}
                  onClick={(event, index) => handleSliceClick(sectionData[index], "section_id")}
                >
                  {sectionData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={PIE_CHART_COLORS[index % sectionData.length]} />
                  ))}
                </Pie>
                {!location && (
                  <>
                    <Tooltip content={(props) => customizedTooltip({ ...props, data: locationData, isLocation: !!location })} />
                    <Pie
                      data={locationData}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius="0%"
                      outerRadius="40%"
                      fill="#8884d8"
                      labelLine={false}
                      label={renderCustomizedLocationLabel}
                      onClick={(event, index) => handleSliceClick(locationData[index], "location")}
                    >
                      {locationData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={PIE_CHART_COLORS[index % sectionData.length]} />
                      ))}
                    </Pie>
                    </>
                )}
              </PieChart>
          : (
            <Box
              display="flex"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              No data to display
            </Box>
          )}
      </ResponsiveContainer>
    </Box>
  );
};
