import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import {EventSummaryRow} from '../EventSummaryList';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

export type Product =
  | 'commtrac_enabled'
  | 'ams_enabled'
  | 'proximity_enabled'
  | 'hazard_ai_enabled'
  | 'alarm_enabled'
  | 'belt_enabled'
  | 'shaft_enabled';

type CompanyProduct = {
  [key in Product]: boolean;
};

const products: Record<Product, string> = {
  commtrac_enabled: 'connect',
  ams_enabled: 'ams',
  proximity_enabled: 'proximity',
  hazard_ai_enabled: 'hazard_ai',
  alarm_enabled: 'alarm',
  belt_enabled: 'belt',
  shaft_enabled: 'shaft_clearance',
};

interface Props<T extends ComponentType> {
  event: EventSummaryRow;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const EventStatAckButton = <T extends ComponentType = typeof Button>({
  event,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;
  const company = useAppSelector(({assets}) => assets.company);
  const {enqueueSnackbar} = useSnackbar();

  const company_product_status: CompanyProduct = useMemo(
    () =>
      [
        'commtrac_enabled',
        'ams_enabled',
        'proximity_enabled',
        'hazard_ai_enabled',
        'alarm_enabled',
        'belt_enabled',
        'shaft_enabled',
      ].reduce((acc, key) => {
        acc[key as Product] =
          company && company[key as Product] ? company[key as Product] : false;
        return acc;
      }, {} as CompanyProduct),
    [company]
  );

  /*********/
  /* submit */
  /*********/
  const payload = useMemo(
    () =>
      Object.keys(company_product_status)
        .filter((key) => company_product_status[key as Product])
        .map((key) => products[key as Product]),
    [company_product_status]
  );
  console.log(payload);

  const params = useMemo(
    () =>
      event.ackAll
        ? {product: event.initialProduct
            ? event.initialProduct.map((it) => products[it])
            : payload}
        : event?.product?.recent?.product === 'connect'
          ? {ids: {connect: [event?.product?.recent?.id]}}
          : event?.product?.recent?.product === 'ams'
            ? {ids: {ams: [event?.product?.recent?.id]}}
            : event?.product?.recent?.product === 'belt'
              ? {ids: {belt: [event?.product?.recent?.id]}}
              : event?.product?.recent?.product === 'shaft_clearance'
                ? {ids: {shaft_clearance: [event?.product?.recent?.id]}}
                : {ids: {alarm: [event?.product?.recent?.id]}},
    [payload]
  );

  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitAckEvent = useCallback(async () => {
    setSubmittedInProgress(true);

    try {
      const endpoint = `${apiBaseUrl}/event/${event.ackAll ? 'acknowledge-all' : 'acknowledge'}`;

      await API.patch(endpoint, params);
      enqueueSnackbar('Event(s) was successfully marked as acknowledged.', {
        variant: 'success',
        action: CloseSnackbarAction,
      });

      onDone?.();
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  }, [event]);

  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Dialog open={isOpened} onClose={closeModal}>
        <DialogTitle>Mark Event</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {event.ackAll
              ? 'Are you sure you want to mark these events as acknowledge?'
              : 'Are you sure you want to mark this event as acknowledge?'}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsOpened(false)}>Cancel</Button>

          <LoadingButton
            variant="contained"
            loading={submittedInProgress}
            autoFocus
            onClick={submitAckEvent}
            color="error"
          >
            {event.ackAll ? 'ACK ALL' : 'ACK'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventStatAckButton;
