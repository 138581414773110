import {
  AlarmOutlined,
  DensitySmall,
  GasMeterOutlined,
} from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CampaignIcon from '@mui/icons-material/Campaign';
import CellTowerIcon from '@mui/icons-material/CellTower';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import update from 'immutability-helper';
import isEqual from 'lodash/isEqual';
import {useEffect, useMemo, useState} from 'react';
import {useComponentSize} from 'react-use-size';

import {useAppSelector} from '../../../hooks/redux';
import {boxStat, boxStatActive} from '../../../hooks/theme';
import {AlarmLogNodeListResponse} from '../../../interfaces/AlarmLogNode';
import {AlarmType} from '../../../interfaces/AlarmType';
import {AMSEModuleNodeListResponse} from '../../../interfaces/AMSEModuleNode';
import {BeltMonitoringNodeListResponse} from '../../../interfaces/BeltMonitoringNode';
import {
  CommtracNodeListQuery,
  CommtracNodeListResponse,
} from '../../../interfaces/CommtracNode';
import {
  EventSummaryProductValue,
  EventSummaryResponse,
} from '../../../interfaces/Event';
import {GasMonitoringNodeListResponse} from '../../../interfaces/GasMonitoringNode';
import {NodeListQuery, NodeListResponse} from '../../../interfaces/Node';
import reduxSelectors from '../../../redux/selectors';
import {
  getLastSummaryEventDescription,
  getLastSummaryEventProduct,
} from '../../../utils/events';
import TruncatedText from '../../common/TruncantedText';
import SummaryEventButton from '../../event-stat/button/SummaryEventButton';
import {AlarmLogReportData} from './AlarmLogReport';
import {BeltMonitoringReportData} from './BeltMonitoringReport';
import {CommtracNodesReportData} from './CommtracNodesReport';
import {AMSEModulesReportData} from './EModulesReport';
import {GasMonitoringReportData} from './GasMonitoringReport';
import {NodesReportData} from './NodesReport';

interface Propx {
  activeTab?: ConnectTab;
  commtracNodesTotal?: CommtracNodeListResponse['total'];
  commtracNodesConfig: CommtracNodesReportData;
  fullTabs: boolean;
  nodesTotal?: NodeListResponse['total'];
  nodesConfig: NodesReportData;
  eventsCount?: number;
  eventSummaryData?: EventSummaryResponse;
  isNodesLoading?: boolean;
  isGasMonitoringNodesLoading?: boolean;
  isEModulesNodesLoading?: boolean;
  isBeltMonitoringNodesLoading?: boolean;
  isCommtracNodesLoading?: boolean;
  isEventsLoading?: boolean;
  isEventSummaryLoading?: boolean;
  isAlarmLogsLoading?: boolean;
  alarmLogsTotal?: AlarmLogNodeListResponse['total'];
  alarmLogsConfig: AlarmLogReportData;
  gasMonitoringConfig: GasMonitoringReportData;
  eModulesConfig: AMSEModulesReportData;
  beltMonitoringConfig: BeltMonitoringReportData;
  gasMonitoringTotal?: GasMonitoringNodeListResponse['total'];
  emoduleTotal?: AMSEModuleNodeListResponse['total'];
  beltMonitoringTotal?: BeltMonitoringNodeListResponse['total'];
  onChangeAlertLogConfig?: (value: AlarmLogReportData) => void;
  onChangeCommtracNodesConfig?: (value: CommtracNodesReportData) => void;
  onChangeGasMonitoringConfig?: (value: GasMonitoringReportData) => void;
  onChangeEModulesConfig?: (value: AMSEModulesReportData) => void;
  onChangeBeltMonitoringConfig?: (value: BeltMonitoringReportData) => void;
  onChangeNodesConfig?: (value: NodesReportData) => void;
  onChangeActiveTab?: (value: ConnectTab) => void;
  onEventSummaryFetch?: () => void;
}

export type ConnectTab =
  | 'commtracNodes'
  | 'nodes'
  | 'events'
  | 'alarmLog'
  | 'gasMonitoring'
  | 'eModules'
  | 'beltMonitoring';

// eslint-disable-next-line complexity
export const ConnectViewStats = ({
  activeTab,
  commtracNodesTotal,
  commtracNodesConfig,
  fullTabs,
  nodesTotal,
  nodesConfig,
  eventSummaryData,
  eventsCount,
  isNodesLoading,
  isGasMonitoringNodesLoading,
  isEModulesNodesLoading,
  isBeltMonitoringNodesLoading,
  isCommtracNodesLoading,
  isEventsLoading,
  isEventSummaryLoading,
  isAlarmLogsLoading,
  alarmLogsTotal,
  alarmLogsConfig,
  gasMonitoringConfig,
  eModulesConfig,
  beltMonitoringConfig,
  gasMonitoringTotal,
  emoduleTotal,
  beltMonitoringTotal,
  onChangeAlertLogConfig,
  onChangeActiveTab,
  onEventSummaryFetch,
  onChangeCommtracNodesConfig,
  onChangeNodesConfig,
  onChangeGasMonitoringConfig,
  onChangeEModulesConfig,
  onChangeBeltMonitoringConfig,
}: Propx) => {
  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);
  const company = useAppSelector(({assets}) => assets.company);
  const me = useAppSelector(({app}) => app.me);
  const userRole = me?.type_id ?? null;
  const isCompactMode = useAppSelector(reduxSelectors.app.getIsCompactMode);

  const boxStatSx: SxProps<Theme> = useMemo(() => {
    return {
      ...boxStat,
      height: isCompactMode ? (fullTabs ? 102 : 47) : 178,
      padding: isCompactMode ? '2px' : '16px 8px',
    };
  }, [isCompactMode, boxStat, fullTabs]);

  const boxStatActiveSx: SxProps<Theme> = useMemo(() => {
    return {
      ...boxStatActive,
      padding: isCompactMode ? '2px' : '16px 8px',
    };
  }, [isCompactMode, boxStatActive]);

  const iconFontSize = useMemo(
    () => (isCompactMode ? 30 : 46),
    [isCompactMode]
  );

  /*****************/
  /* miners status */
  /*****************/

  const [minersMenuAnchorEl, setMinersMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const minersMenuOpen = Boolean(minersMenuAnchorEl);

  const minerStatusOptions: {
    value: CommtracNodeListQuery['miner_status'];
    label: string;
  }[] = useMemo(
    () => [
      {value: 'checked_in', label: 'Checked In'},
      {value: 'checked_out', label: 'Checked Out'},
      {value: 'active', label: 'Active'},
      {value: 'inactive', label: 'Inactive'},
      {value: 'unacknowledged', label: 'Unacknowledged'},
      {value: 'surface', label: 'Surface'},
      {value: 'underground', label: 'Underground'},
      {value: 'all', label: 'All'},
    ],
    []
  );

  const selectedMinerStatusOption = useMemo(
    () =>
      minerStatusOptions.find(
        (i) => i.value === commtracNodesConfig.params.miner_status
      ),
    [commtracNodesConfig.params.miner_status, minerStatusOptions]
  );

  /****************/
  /* nodes status */
  /****************/

  const [nodesMenuAnchorEl, setNodesMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const nodesMenuOpen = Boolean(nodesMenuAnchorEl);

  const nodeStatusOptions: {
    value: NodeListQuery['communication_node_status'];
    label: string;
  }[] = useMemo(
    () => [
      {value: 'all', label: 'All'},
      {value: 'active', label: 'Active'},
      {value: 'inactive', label: 'Inactive'},
      {value: 'connected', label: 'Connected'},
      {value: 'disconnected', label: 'Disconnected'},
      {value: 'unacknowledged', label: 'Unacknowledged'},
    ],
    []
  );

  const selectedNodeStatusOption = useMemo(
    () =>
      nodeStatusOptions.find(
        (i) => i.value === nodesConfig?.params.communication_node_status
      ),
    [nodesConfig?.params.communication_node_status, nodeStatusOptions]
  );

  const nodeTypeOptions: {
    value: NodeListQuery['node_type'][number] | 'all';
    label: string;
  }[] = useMemo(
    () => [
      {value: 'all', label: 'All'},
      {value: 'communication_node', label: 'Communication Node'},
      {value: 'wifi_point', label: 'WiFi Nodes'},
    ],
    []
  );

  const nodeType = useMemo(() => {
    if (isEqual(nodesConfig.params?.node_type, ['communication_node'])) {
      return 'communication_node';
    } else if (isEqual(nodesConfig.params?.node_type, ['wifi_point'])) {
      return 'wifi_point';
    }
    return 'all';
  }, [nodesConfig.params?.node_type]);

  const selectedNodeTypeOption = useMemo(() => {
    if (nodeType === 'communication_node') {
      return nodeTypeOptions.find((i) => i.value === 'communication_node');
    } else if (nodeType === 'wifi_point') {
      return nodeTypeOptions.find((i) => i.value === 'wifi_point');
    }
    return nodeTypeOptions.find((i) => i.value === 'all');
  }, [nodesConfig.params?.node_type, nodeTypeOptions]);

  /*****************/
  /* assets status */
  /*****************/

  const [assetsMenuAnchorEl, setAssetsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const assetsMenuOpen = Boolean(assetsMenuAnchorEl);

  const assetStatusOptions: {
    value: CommtracNodeListQuery['asset_status'];
    label: string;
  }[] = useMemo(
    () => [
      {value: 'checked_in', label: 'Checked In'},
      {value: 'checked_out', label: 'Checked Out'},
      {value: 'active', label: 'Active'},
      {value: 'inactive', label: 'Inactive'},
      {value: 'unacknowledged', label: 'Unacknowledged'},
      {value: 'surface', label: 'Surface'},
      {value: 'underground', label: 'Underground'},
      {value: 'all', label: 'All'},
    ],
    []
  );

  const selectedAssetStatusOption = useMemo(
    () =>
      assetStatusOptions.find(
        (i) => i.value === commtracNodesConfig.params.asset_status
      ),
    [commtracNodesConfig.params.asset_status, assetStatusOptions]
  );

  /****************/
  /*

  status */
  /****************/

  const lastSummaryEventDescription = useMemo(() => {
    return (
      getLastSummaryEventDescription(
        eventSummaryData?.connect,
        eventSummaryData?.ams,
        eventSummaryData?.belt,
        eventSummaryData?.shaft_clearance
      ) ?? 'Events'
    );
  }, [
    eventSummaryData?.connect,
    eventSummaryData?.ams,
    eventSummaryData?.belt,
    eventSummaryData?.shaft_clearance,
    company,
  ]);

  const lastSummaryEventProduct: EventSummaryProductValue | undefined | null =
    useMemo(() => {
      return (
        getLastSummaryEventProduct(
          eventSummaryData?.connect,
          eventSummaryData?.ams,
          eventSummaryData?.belt,
          eventSummaryData?.shaft_clearance
        ) ?? undefined
      );
    }, [
      eventSummaryData?.connect,
      eventSummaryData?.ams,
      eventSummaryData?.belt,
      eventSummaryData?.shaft_clearance,
      company,
    ]);

  /*********/
  /* sizes */
  /*********/

  const {ref} = useComponentSize();
  const rowMeasure =
    12 / (5 + (company?.ams_enabled ? 1 : 0) + (company?.belt_enabled ? 1 : 0));

  /**
   * Alarm Log
   */

  const [alarmStatusMenuAnchorEl, setAlarmStatusMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const alarmStatusMenuOpen = Boolean(alarmStatusMenuAnchorEl);

  const alarmStatusOptions: {
    value: AlarmType['status'] | 'all' | 'unacknowledged';
    label: string;
  }[] = useMemo(
    () => [
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'unacknowledged',
        label: 'Unacknowledged',
      },
    ],
    []
  );

  const selectedAlarmStatusOption = useMemo(
    () =>
      alarmStatusOptions.find((i) => i.value === alarmLogsConfig.params.status),
    [alarmLogsConfig.params.status, alarmStatusOptions]
  );

  /*************************/
  /* gas monitoring status */
  /*************************/

  const [gasCatMenuAnchorEl, setGasCatMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const gasCatMenuOpen = Boolean(gasCatMenuAnchorEl);

  const [amsSelectedCategory, setAmsSelectedCategory] = useState<{
    activeCategory: ConnectTab;
    categoryName: string;
  }>({activeCategory: 'gasMonitoring', categoryName: 'SENSORS'});
  const [amsSelectedStatus, setAmsSelectedStatus] = useState<
    'active' | 'inactive' | 'assigned' | 'unassigned' | 'all' | 'unacknowledged'
  >('all');

  const isAMSTabActive =
    activeTab === 'gasMonitoring' || activeTab === 'eModules';

  const gasCatOptions: {
    value: 'sensors' | 'e-modules';
    label: string;
    viewType: ConnectTab;
  }[] = useMemo(
    () =>
      userRole === 1 || userRole === 2 || userRole === 3
        ? [
            {
              value: 'sensors',
              label: 'Sensors',
              viewType: 'gasMonitoring',
            },
            {
              value: 'e-modules',
              label: 'e-Modules',
              viewType: 'eModules',
            },
          ]
        : [
            {
              value: 'sensors',
              label: 'Sensors',
              viewType: 'gasMonitoring',
            },
          ],
    [userRole]
  );

  const gasStatusOptions: {
    value: 'all' | 'active' | 'inactive' | 'unacknowledged';
    label: string;
  }[] = useMemo(
    () => [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
      {
        value: 'unacknowledged',
        label: 'Unacknowledged',
      },
    ],
    []
  );

  const eModuleStatusOptions: {
    value: 'all' | 'active' | 'inactive' | 'assigned' | 'unassigned';
    label: string;
  }[] = useMemo(
    () => [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },

      {
        value: 'assigned',
        label: 'Assigned',
      },
      {
        value: 'unassigned',
        label: 'Unassigned',
      },
    ],
    []
  );

  /*************************/
  /* belt monitoring status */
  /*************************/
  const [beltCatMenuAnchorEl, setBeltCatMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const beltCatMenuOpen = Boolean(beltCatMenuAnchorEl);

  const beltStatusOption: {
    value: 'all' | 'active' | 'inactive' | 'unacknowledged';
    label: string;
  }[] = useMemo(
    () => [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
      {
        value: 'unacknowledged',
        label: 'Unacknowledged',
      },
    ],
    []
  );

  const selectedBeltStatusOption = useMemo(
    () =>
      beltStatusOption.find(
        (i) => i.value === beltMonitoringConfig?.params?.status
      ),
    [beltMonitoringConfig?.params.status, beltStatusOption]
  );

  useEffect(() => {
    if (!company?.belt_enabled && activeTab === 'beltMonitoring') {
      onChangeActiveTab?.('nodes');
    }
  }, [company?.belt_enabled]);

  // ?Effect which helps to show actual amsSelected category relying on current activeTab value and the one chosen previously stored in amsSelectedCategory even if !isAMSTabActive
  useEffect(() => {
    if (activeTab === 'eModules') {
      setAmsSelectedCategory({
        activeCategory: 'eModules',
        categoryName: 'EMODULES',
      });
    }

    if (activeTab === 'gasMonitoring') {
      setAmsSelectedCategory({
        activeCategory: 'gasMonitoring',
        categoryName: 'SENSORS',
      });
    }
  }, [activeTab]);

  // ?Keep current Status for chosen activeTab actual
  useEffect(() => {
    if (activeTab === 'eModules') {
      setAmsSelectedStatus(eModulesConfig?.params?.status ?? 'all');
    }

    if (activeTab === 'gasMonitoring') {
      setAmsSelectedStatus(gasMonitoringConfig?.params?.status ?? 'all');
    }
  }, [
    gasMonitoringConfig?.params?.status,
    eModulesConfig?.params?.status,
    activeTab,
    amsSelectedStatus,
  ]);

  // Events
  const unAckCounts = useMemo(() => {
    if (company?.ams_enabled && company?.belt_enabled) {
      return (
        (eventSummaryData?.connect?.unacked ?? 0) +
        (eventSummaryData?.ams?.unacked ?? 0) +
        (eventSummaryData?.belt?.unacked ?? 0)
      );
    } else if (company?.ams_enabled) {
      return (
        (eventSummaryData?.connect?.unacked ?? 0) +
        (eventSummaryData?.ams?.unacked ?? 0)
      );
    } else if (company?.belt_enabled) {
      return (
        (eventSummaryData?.connect?.unacked ?? 0) +
        (eventSummaryData?.belt?.unacked ?? 0)
      );
    } else {
      return eventSummaryData?.connect?.unacked ?? 0;
    }
  }, [eventSummaryData]);

  const totalEventCounts = useMemo(() => {
    if (company?.ams_enabled && company?.belt_enabled) {
      return (
        (eventSummaryData?.connect?.count ?? 0) +
        (eventSummaryData?.ams?.count ?? 0) +
        (eventSummaryData?.belt?.count ?? 0)
      );
    } else if (company?.ams_enabled) {
      return (
        (eventSummaryData?.connect?.count ?? 0) +
        (eventSummaryData?.ams?.count ?? 0)
      );
    } else if (company?.belt_enabled) {
      return (
        (eventSummaryData?.connect?.count ?? 0) +
        (eventSummaryData?.belt?.count ?? 0)
      );
    } else {
      return eventSummaryData?.connect?.count ?? 0;
    }
  }, [eventSummaryData]);

  return (
    <Box ref={ref} sx={{overflowX: 'auto'}}>
      <Grid container minWidth={1200}>
        {/******* Start Nodes Tab *******/}
        <Grid item sx={{flexGrow: 1}} position="relative">
          <Backdrop open={!!isNodesLoading} sx={{position: 'absolute'}} />

          <Box
            sx={{
              ...(activeTab === 'nodes' ? boxStatActiveSx : boxStatSx),
            }}
            onClick={() => onChangeActiveTab?.('nodes')}
          >
            {fullTabs ? (
              <>
                <CellTowerIcon
                  sx={{fontSize: iconFontSize, alignContent: 'left'}}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  height="100%"
                  sx={{alignContent: 'right'}}
                >
                  {nodesTotal ? (
                    <>
                      {nodeType === 'communication_node' ? (
                        <Box>{nodesTotal.filter.communication_node}</Box>
                      ) : nodeType === 'wifi_point' ? (
                        <Box>{nodesTotal.filter.wifi_point}</Box>
                      ) : (
                        <Box>
                          {nodesTotal.filter.communication_node +
                            nodesTotal.filter.wifi_point}
                        </Box>
                      )}
                      <Box>/</Box>
                      <Box>
                        {nodeType === 'communication_node'
                          ? nodesTotal.all.communication_node
                          : nodeType === 'wifi_point'
                            ? nodesTotal.all.wifi_point
                            : nodesTotal.all.communication_node +
                              nodesTotal.all.wifi_point}
                      </Box>
                    </>
                  ) : (
                    <HourglassEmptyIcon />
                  )}
                </Box>
                <Button
                  variant="text"
                  color="inherit"
                  sx={{width: '100%'}}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(event) => setNodesMenuAnchorEl(event.currentTarget)}
                >
                  <TruncatedText>
                    Nodes {selectedNodeStatusOption?.label} (
                    {selectedNodeTypeOption?.label})
                  </TruncatedText>
                </Button>
                <Menu
                  anchorEl={nodesMenuAnchorEl}
                  open={nodesMenuOpen}
                  sx={{zIndex: 10000}}
                  onBackdropClick={() => setNodesMenuAnchorEl(null)}
                >
                  <Typography
                    textTransform="uppercase"
                    fontWeight={400}
                    color="text.disabled"
                    p={2}
                    pb={1}
                  >
                    Nodes
                  </Typography>
                  {nodeTypeOptions.map((i) => (
                    <MenuItem
                      key={i.value}
                      selected={i.value === nodeType}
                      onClick={() => {
                        const nodeType = i.value === 'all' ? [] : [i.value];
                        onChangeNodesConfig?.(
                          update(nodesConfig, {
                            params: {
                              node_type: {
                                $set: nodeType,
                              },
                            },
                            grid: {
                              page: {
                                $set: 0,
                              },
                            },
                          })
                        );
                        setNodesMenuAnchorEl(null);
                      }}
                    >
                      {i.label}
                    </MenuItem>
                  ))}
                  <Divider />
                  <Typography
                    textTransform="uppercase"
                    fontWeight={400}
                    color="text.disabled"
                    p={2}
                    pb={1}
                  >
                    Status
                  </Typography>
                  {nodeStatusOptions.map((i) => (
                    <MenuItem
                      key={i.value}
                      selected={
                        i.value === nodesConfig.params.communication_node_status
                      }
                      onClick={() => {
                        onChangeNodesConfig?.(
                          update(nodesConfig, {
                            params: {
                              communication_node_status: {
                                $set: i.value,
                              },
                              wifi_point_status: {
                                $set: i.value,
                              },
                            },
                            grid: {
                              page: {
                                $set: 0,
                              },
                            },
                          })
                        );
                        setNodesMenuAnchorEl(null);
                      }}
                    >
                      {i.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <Box>
                <Button
                  variant="text"
                  color="inherit"
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(event) => setNodesMenuAnchorEl(event.currentTarget)}
                >
                  <CellTowerIcon sx={{fontSize: iconFontSize}} />
                  <TruncatedText>Nodes</TruncatedText>
                </Button>
                <Menu
                  anchorEl={nodesMenuAnchorEl}
                  open={nodesMenuOpen}
                  sx={{zIndex: 10000}}
                  onBackdropClick={() => setNodesMenuAnchorEl(null)}
                >
                  <Typography
                    textTransform="uppercase"
                    fontWeight={400}
                    color="text.disabled"
                    p={2}
                    pb={1}
                  >
                    Nodes
                  </Typography>
                  {nodeTypeOptions.map((i) => (
                    <MenuItem
                      key={i.value}
                      selected={i.value === nodeType}
                      onClick={() => {
                        const nodeType = i.value === 'all' ? [] : [i.value];
                        onChangeNodesConfig?.(
                          update(nodesConfig, {
                            params: {
                              node_type: {
                                $set: nodeType,
                              },
                            },
                            grid: {
                              page: {
                                $set: 0,
                              },
                            },
                          })
                        );
                        setNodesMenuAnchorEl(null);
                      }}
                    >
                      {i.label}
                    </MenuItem>
                  ))}
                  <Divider />
                  <Typography
                    textTransform="uppercase"
                    fontWeight={400}
                    color="text.disabled"
                    p={2}
                    pb={1}
                  >
                    Status
                  </Typography>
                  {nodeStatusOptions.map((i) => (
                    <MenuItem
                      key={i.value}
                      selected={
                        i.value === nodesConfig.params.communication_node_status
                      }
                      onClick={() => {
                        onChangeNodesConfig?.(
                          update(nodesConfig, {
                            params: {
                              communication_node_status: {
                                $set: i.value,
                              },
                              wifi_point_status: {
                                $set: i.value,
                              },
                            },
                            grid: {
                              page: {
                                $set: 0,
                              },
                            },
                          })
                        );
                        setNodesMenuAnchorEl(null);
                      }}
                    >
                      {i.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}{' '}
          </Box>
        </Grid>
        {/******* End Nodes Tab *******/}

        {/******* Start Employees Tab *******/}
        <Grid item sx={{flexGrow: 1}} position="relative">
          <Backdrop
            open={!!isCommtracNodesLoading}
            sx={{position: 'absolute'}}
          />
          <Box
            sx={{
              ...(activeTab === 'commtracNodes' ? boxStatActiveSx : boxStatSx),
            }}
            onClick={() => onChangeActiveTab?.('commtracNodes')}
          >
            {fullTabs ? (
              <>
                <PersonIcon sx={{fontSize: iconFontSize}} />

                <Box display="flex" alignItems="center" gap={1} height="100%">
                  {commtracNodesTotal ? (
                    <>
                      <Box>{commtracNodesTotal?.filter.miner}</Box>
                      <Box>/</Box>
                      <Box>{commtracNodesTotal?.all.miner}</Box>
                    </>
                  ) : (
                    <HourglassEmptyIcon fontSize="large" />
                  )}
                </Box>

                <Button
                  variant="text"
                  color="inherit"
                  sx={{width: '100%'}}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(event) =>
                    setMinersMenuAnchorEl(event.currentTarget)
                  }
                >
                  <TruncatedText>
                    Employees {selectedMinerStatusOption?.label}
                  </TruncatedText>
                </Button>

                <Menu
                  anchorEl={minersMenuAnchorEl}
                  open={minersMenuOpen}
                  sx={{zIndex: 10000}}
                  onBackdropClick={() => setMinersMenuAnchorEl(null)}
                >
                  {minerStatusOptions.map((i) => (
                    <MenuItem
                      key={i.value}
                      selected={
                        i.value === commtracNodesConfig.params.miner_status
                      }
                      onClick={() => {
                        onChangeCommtracNodesConfig?.(
                          update(commtracNodesConfig, {
                            params: {
                              miner_status: {
                                $set: i.value,
                              },
                            },
                            grid: {
                              page: {
                                $set: 0,
                              },
                            },
                          })
                        );
                        setMinersMenuAnchorEl(null);
                      }}
                    >
                      {i.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <Box>
                <Button
                  variant="text"
                  color="inherit"
                  sx={{width: '100%'}}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(event) =>
                    setMinersMenuAnchorEl(event.currentTarget)
                  }
                >
                  <PersonIcon sx={{fontSize: iconFontSize}} />
                  <TruncatedText>Employees</TruncatedText>
                </Button>

                <Menu
                  anchorEl={minersMenuAnchorEl}
                  open={minersMenuOpen}
                  sx={{zIndex: 10000}}
                  onBackdropClick={() => setMinersMenuAnchorEl(null)}
                >
                  {minerStatusOptions.map((i) => (
                    <MenuItem
                      key={i.value}
                      selected={
                        i.value === commtracNodesConfig.params.miner_status
                      }
                      onClick={() => {
                        onChangeCommtracNodesConfig?.(
                          update(commtracNodesConfig, {
                            params: {
                              miner_status: {
                                $set: i.value,
                              },
                            },
                            grid: {
                              page: {
                                $set: 0,
                              },
                            },
                          })
                        );
                        setMinersMenuAnchorEl(null);
                      }}
                    >
                      {i.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Box>
        </Grid>
        {/******* End Employees Tab *******/}

        {/******* Start Assets Tab *******/}
        <Grid item sx={{flexGrow: 1}} position="relative">
          <Backdrop
            open={!!isCommtracNodesLoading}
            sx={{position: 'absolute'}}
          />
          <Box
            sx={{
              ...(activeTab === 'commtracNodes' ? boxStatActiveSx : boxStatSx),
            }}
            onClick={() => onChangeActiveTab?.('commtracNodes')}
          >
            {fullTabs ? (
              <>
                <LocalShippingIcon sx={{fontSize: iconFontSize}} />

                <Box display="flex" alignItems="center" gap={1} height="100%">
                  {commtracNodesTotal ? (
                    <>
                      <Box>{commtracNodesTotal?.filter.asset}</Box>
                      <Box>/</Box>
                      <Box>{commtracNodesTotal?.all.asset}</Box>
                    </>
                  ) : (
                    <HourglassEmptyIcon fontSize="large" />
                  )}
                </Box>
                <Button
                  variant="text"
                  color="inherit"
                  sx={{width: '100%'}}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(event) =>
                    setAssetsMenuAnchorEl(event.currentTarget)
                  }
                >
                  <TruncatedText>
                    Assets {selectedAssetStatusOption?.label}
                  </TruncatedText>
                </Button>

                <Menu
                  anchorEl={assetsMenuAnchorEl}
                  open={assetsMenuOpen}
                  sx={{zIndex: 10000}}
                  onBackdropClick={() => setAssetsMenuAnchorEl(null)}
                >
                  {assetStatusOptions.map((i) => (
                    <MenuItem
                      key={i.value}
                      selected={
                        i.value === commtracNodesConfig.params.asset_status
                      }
                      onClick={() => {
                        onChangeCommtracNodesConfig?.(
                          update(commtracNodesConfig, {
                            params: {
                              asset_status: {
                                $set: i.value,
                              },
                            },
                            grid: {
                              page: {
                                $set: 0,
                              },
                            },
                          })
                        );
                        setAssetsMenuAnchorEl(null);
                      }}
                    >
                      {i.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <Box>
                <Button
                  variant="text"
                  color="inherit"
                  sx={{width: '100%'}}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(event) =>
                    setAssetsMenuAnchorEl(event.currentTarget)
                  }
                >
                  <LocalShippingIcon sx={{fontSize: iconFontSize}} />

                  <TruncatedText>Assets</TruncatedText>
                </Button>

                <Menu
                  anchorEl={assetsMenuAnchorEl}
                  open={assetsMenuOpen}
                  sx={{zIndex: 10000}}
                  onBackdropClick={() => setAssetsMenuAnchorEl(null)}
                >
                  {assetStatusOptions.map((i) => (
                    <MenuItem
                      key={i.value}
                      selected={
                        i.value === commtracNodesConfig.params.asset_status
                      }
                      onClick={() => {
                        onChangeCommtracNodesConfig?.(
                          update(commtracNodesConfig, {
                            params: {
                              asset_status: {
                                $set: i.value,
                              },
                            },
                            grid: {
                              page: {
                                $set: 0,
                              },
                            },
                          })
                        );
                        setAssetsMenuAnchorEl(null);
                      }}
                    >
                      {i.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Box>
        </Grid>
        {/******* End Assets Tab *******/}

        {/******* Start AMS Tab (For only AMS Enabled) *******/}
        {company?.ams_enabled && (
          <Grid item sx={{flexGrow: 1}} position="relative">
            <Backdrop
              open={!!isGasMonitoringNodesLoading || !!isEModulesNodesLoading}
              sx={{position: 'absolute'}}
            />
            <Box
              sx={{
                ...(isAMSTabActive ? boxStatActiveSx : boxStatSx),
              }}
              onClick={() => {
                if (!isAMSTabActive) {
                  onChangeActiveTab?.(amsSelectedCategory.activeCategory);
                }
              }}
            >
              {fullTabs ? (
                <>
                  <GasMeterOutlined sx={{fontSize: iconFontSize}} />
                  <Box display="flex" alignItems="center" gap={1} height="100%">
                    {(amsSelectedCategory.activeCategory === 'eModules' &&
                      emoduleTotal) ||
                    (amsSelectedCategory.activeCategory === 'gasMonitoring' &&
                      gasMonitoringTotal) ? (
                      <>
                        {amsSelectedCategory.activeCategory ===
                          'gasMonitoring' && gasMonitoringTotal ? (
                          <>
                            <Box>{gasMonitoringTotal.filter}</Box>
                            <Box>/</Box>
                            <Box>{gasMonitoringTotal.all}</Box>
                          </>
                        ) : null}
                        {amsSelectedCategory.activeCategory === 'eModules' &&
                        emoduleTotal ? (
                          <>
                            <Box>{emoduleTotal.filter}</Box>
                            <Box>/</Box>
                            <Box>{emoduleTotal.all}</Box>
                          </>
                        ) : null}
                      </>
                    ) : (
                      <HourglassEmptyIcon fontSize="large" />
                    )}
                  </Box>
                  <Box display="flex" width="100%">
                    <Button
                      variant="text"
                      color="inherit"
                      sx={{width: '100%'}}
                      endIcon={<ArrowDropDownIcon />}
                      onClick={(event) =>
                        setGasCatMenuAnchorEl(event.currentTarget)
                      }
                    >
                      <TruncatedText>
                        {amsSelectedCategory.categoryName} {amsSelectedStatus}
                      </TruncatedText>
                    </Button>
                  </Box>
                  <Menu
                    anchorEl={gasCatMenuAnchorEl}
                    open={gasCatMenuOpen}
                    sx={{zIndex: 10000, width: '100%'}}
                    onBackdropClick={() => setGasCatMenuAnchorEl(null)}
                  >
                    {gasCatOptions.map((i) => (
                      <MenuItem
                        key={i.value}
                        selected={i.viewType === activeTab}
                        onClick={() => {
                          onChangeActiveTab?.(i.viewType);
                          setGasCatMenuAnchorEl(null);
                        }}
                      >
                        {i.label}
                      </MenuItem>
                    ))}

                    <Divider />

                    <Typography
                      textTransform="uppercase"
                      fontWeight={400}
                      color="text.disabled"
                      p={2}
                      pb={1}
                    >
                      Status
                    </Typography>
                    {activeTab === 'eModules'
                      ? eModuleStatusOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            selected={
                              option.value === eModulesConfig?.params?.status
                            }
                            onClick={() => {
                              const updatedConfig = update(eModulesConfig, {
                                params: {
                                  status: {
                                    $set: option.value,
                                  },
                                },
                              });

                              onChangeEModulesConfig?.(updatedConfig);
                              setGasCatMenuAnchorEl(null);
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        ))
                      : null}
                    {activeTab === 'gasMonitoring'
                      ? gasStatusOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            selected={
                              option.value === gasMonitoringConfig.params.status
                            }
                            onClick={() => {
                              const updatedConfig = update(
                                gasMonitoringConfig,
                                {
                                  params: {
                                    status: {
                                      $set: option.value,
                                    },
                                  },
                                }
                              );

                              onChangeGasMonitoringConfig?.(updatedConfig);
                              setGasCatMenuAnchorEl(null);
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        ))
                      : null}
                  </Menu>
                </>
              ) : (
                <Box>
                  <Box display="flex" width="100%">
                    <Button
                      variant="text"
                      color="inherit"
                      sx={{width: '100%'}}
                      endIcon={<ArrowDropDownIcon />}
                      onClick={(event) =>
                        setGasCatMenuAnchorEl(event.currentTarget)
                      }
                    >
                      <GasMeterOutlined sx={{fontSize: iconFontSize}} />
                      <TruncatedText>AMS</TruncatedText>
                    </Button>
                  </Box>
                  <Menu
                    anchorEl={gasCatMenuAnchorEl}
                    open={gasCatMenuOpen}
                    sx={{zIndex: 10000, width: '100%'}}
                    onBackdropClick={() => setGasCatMenuAnchorEl(null)}
                  >
                    {gasCatOptions.map((i) => (
                      <MenuItem
                        key={i.value}
                        selected={i.viewType === activeTab}
                        onClick={() => {
                          onChangeActiveTab?.(i.viewType);
                          setGasCatMenuAnchorEl(null);
                        }}
                      >
                        {i.label}
                      </MenuItem>
                    ))}

                    <Divider />

                    <Typography
                      textTransform="uppercase"
                      fontWeight={400}
                      color="text.disabled"
                      p={2}
                      pb={1}
                    >
                      Status
                    </Typography>
                    {activeTab === 'eModules'
                      ? eModuleStatusOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            selected={
                              option.value === eModulesConfig?.params?.status
                            }
                            onClick={() => {
                              const updatedConfig = update(eModulesConfig, {
                                params: {
                                  status: {
                                    $set: option.value,
                                  },
                                },
                              });

                              onChangeEModulesConfig?.(updatedConfig);
                              setGasCatMenuAnchorEl(null);
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        ))
                      : null}
                    {activeTab === 'gasMonitoring'
                      ? gasStatusOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            selected={
                              option.value === gasMonitoringConfig.params.status
                            }
                            onClick={() => {
                              const updatedConfig = update(
                                gasMonitoringConfig,
                                {
                                  params: {
                                    status: {
                                      $set: option.value,
                                    },
                                  },
                                }
                              );

                              onChangeGasMonitoringConfig?.(updatedConfig);
                              setGasCatMenuAnchorEl(null);
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        ))
                      : null}
                  </Menu>
                </Box>
              )}
            </Box>
          </Grid>
        )}
        {/******* End AMS Tab *******/}

        {/******* Start Belt Tab *******/}
        {company?.belt_enabled && (
          <Grid item xs={rowMeasure} position="relative">
            <Backdrop
              open={!!isBeltMonitoringNodesLoading}
              sx={{position: 'absolute'}}
            />
            <Box
              sx={{
                ...(activeTab === 'beltMonitoring'
                  ? boxStatActiveSx
                  : boxStatSx),
              }}
              onClick={() => onChangeActiveTab?.('beltMonitoring')}
            >
              {fullTabs ? (
                <>
                  <DensitySmall sx={{fontSize: iconFontSize}} />
                  <Box display="flex" alignItems="center" gap={1} height="100%">
                    <Box>
                      {beltMonitoringTotal
                        ? beltMonitoringTotal[
                            beltMonitoringConfig.params.status ?? 'all'
                          ]
                        : 0}
                    </Box>
                    <Box>/</Box>
                    <Box>{beltMonitoringTotal?.all || 0}</Box>
                  </Box>
                  <Box display="flex" width="100%">
                    <Button
                      variant="text"
                      color="inherit"
                      sx={{width: '100%'}}
                      endIcon={<ArrowDropDownIcon />}
                      onClick={(event) =>
                        setBeltCatMenuAnchorEl(event.currentTarget)
                      }
                    >
                      <TruncatedText>
                        {`BELTS ${selectedBeltStatusOption ? selectedBeltStatusOption?.label : 'All'}`}
                      </TruncatedText>
                    </Button>
                  </Box>
                  <Menu
                    anchorEl={beltCatMenuAnchorEl}
                    open={beltCatMenuOpen}
                    sx={{zIndex: 10000, width: '100%'}}
                    onBackdropClick={() => setBeltCatMenuAnchorEl(null)}
                  >
                    {beltStatusOption.map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={() => {
                          const updatedConfig = update(beltMonitoringConfig, {
                            params: {
                              status: {
                                $set: option.value,
                              },
                            },
                          });

                          onChangeBeltMonitoringConfig?.(updatedConfig);
                          setBeltCatMenuAnchorEl(null);
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <Box>
                  <Box display="flex" width="100%">
                    <Button
                      variant="text"
                      color="inherit"
                      sx={{width: '100%', alignItems: 'center'}}
                      endIcon={<ArrowDropDownIcon />}
                      onClick={(event) =>
                        setBeltCatMenuAnchorEl(event.currentTarget)
                      }
                    >
                      <DensitySmall sx={{fontSize: iconFontSize}} />
                      <TruncatedText>Belts</TruncatedText>
                    </Button>
                  </Box>
                  <Menu
                    anchorEl={beltCatMenuAnchorEl}
                    open={beltCatMenuOpen}
                    sx={{zIndex: 10000, width: '100%'}}
                    onBackdropClick={() => setBeltCatMenuAnchorEl(null)}
                  >
                    {beltStatusOption.map((i) => (
                      <MenuItem
                        key={i.value}
                        onClick={() => {
                          const updatedConfig = update(beltMonitoringConfig, {
                            params: {
                              status: {
                                $set: i.value,
                              },
                            },
                          });

                          onChangeBeltMonitoringConfig?.(updatedConfig);
                          setBeltCatMenuAnchorEl(null);
                        }}
                      >
                        {i.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              )}
            </Box>
          </Grid>
        )}
        {/******* End Belt Tab *******/}

        {/******* Start Alarms Tab *******/}
        {company?.alarm_enabled && (
          <Grid item sx={{flexGrow: 1}} position="relative">
            <Backdrop open={!!isAlarmLogsLoading} sx={{position: 'absolute'}} />
            <Box
              sx={{
                ...(activeTab === 'alarmLog' ? boxStatActiveSx : boxStatSx),
              }}
              onClick={() => onChangeActiveTab?.('alarmLog')}
            >
              {fullTabs ? (
                <>
                  <AlarmOutlined
                    color={eventSummaryData?.alarm?.count ? 'error' : 'inherit'}
                    sx={{fontSize: iconFontSize}}
                  />
                  <Box display="flex" alignItems="center" gap={1} height="100%">
                    {alarmLogsTotal ? (
                      <>
                        <Box>{alarmLogsTotal?.activated || 0}</Box>
                        <Box>/</Box>
                        <Box>{alarmLogsTotal?.filtered || 0}</Box>
                      </>
                    ) : (
                      <HourglassEmptyIcon fontSize="large" />
                    )}
                  </Box>
                  <Box display="flex" width="100%" alignItems="justify">
                    <Button
                      variant="text"
                      color="inherit"
                      sx={{width: '100%'}}
                      endIcon={<ArrowDropDownIcon />}
                      onClick={(event) =>
                        setAlarmStatusMenuAnchorEl(event.currentTarget)
                      }
                    >
                      <TruncatedText>
                        {`ALARMS ${selectedAlarmStatusOption?.label}`}
                      </TruncatedText>
                    </Button>
                  </Box>
                  <Menu
                    anchorEl={alarmStatusMenuAnchorEl}
                    open={alarmStatusMenuOpen}
                    sx={{zIndex: 10000}}
                    onBackdropClick={() => setAlarmStatusMenuAnchorEl(null)}
                  >
                    {alarmStatusOptions.map((i) => (
                      <MenuItem
                        key={i.value}
                        selected={i.value === alarmLogsConfig.params.status}
                        onClick={() => {
                          onChangeAlertLogConfig?.(
                            update(alarmLogsConfig, {
                              params: {
                                status: {
                                  $set: i.value,
                                },
                              },
                              grid: {
                                page: {
                                  $set: 0,
                                },
                              },
                            })
                          );
                          setAlarmStatusMenuAnchorEl(null);
                        }}
                      >
                        {i.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <Box>
                  <Box display="flex" width="100%" alignItems="justify">
                    <Button
                      variant="text"
                      color="inherit"
                      sx={{width: '100%'}}
                      endIcon={<ArrowDropDownIcon />}
                      onClick={(event) =>
                        setAlarmStatusMenuAnchorEl(event.currentTarget)
                      }
                    >
                      <AlarmOutlined
                        color={
                          eventSummaryData?.alarm?.count ? 'error' : 'inherit'
                        }
                        sx={{fontSize: iconFontSize}}
                      />
                      <TruncatedText>Alarms</TruncatedText>
                    </Button>
                  </Box>
                  <Menu
                    anchorEl={alarmStatusMenuAnchorEl}
                    open={alarmStatusMenuOpen}
                    sx={{zIndex: 10000}}
                    onBackdropClick={() => setAlarmStatusMenuAnchorEl(null)}
                  >
                    {alarmStatusOptions.map((i) => (
                      <MenuItem
                        key={i.value}
                        selected={i.value === alarmLogsConfig.params.status}
                        onClick={() => {
                          onChangeAlertLogConfig?.(
                            update(alarmLogsConfig, {
                              params: {
                                status: {
                                  $set: i.value,
                                },
                              },
                              grid: {
                                page: {
                                  $set: 0,
                                },
                              },
                            })
                          );
                          setAlarmStatusMenuAnchorEl(null);
                        }}
                      >
                        {i.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              )}
            </Box>
          </Grid>
        )}
        {/******* End Alarms Tab *******/}

        {/******* Start Events Tab *******/}
        <Grid item sx={{flexGrow: 1}} position="relative">
          <Backdrop
            open={!!isEventSummaryLoading || !!isEventsLoading}
            sx={{position: 'absolute'}}
          />
          <Box
            overflow="hidden"
            sx={{
              ...(activeTab === 'events' ? boxStatActiveSx : boxStatSx),
            }}
            onClick={() => onChangeActiveTab?.('events')}
          >
            {fullTabs ? (
              <>
                <CampaignIcon
                  sx={{
                    fontSize: iconFontSize,
                    color: (theme) =>
                      eventsCount || eventSummaryData?.connect?.count
                        ? theme.palette.error.main
                        : undefined,
                  }}
                />

                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  height="100%"
                  whiteSpace="nowrap"
                  color={(theme) =>
                    eventsCount ||
                    eventSummaryData?.connect?.count ||
                    (!!company?.ams_enabled && eventSummaryData?.ams?.count)
                      ? theme.palette.error.main
                      : undefined
                  }
                >
                  <Box display="flex" alignItems="center" gap={1} height="100%">
                    {unAckCounts !== null && totalEventCounts !== null ? (
                      <>
                        <Box>{unAckCounts}</Box>
                        <Box>/</Box>
                        <Box>{totalEventCounts}</Box>
                      </>
                    ) : (
                      <HourglassEmptyIcon fontSize="large" />
                    )}
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center">
                  {unAckCounts ? (
                    <SummaryEventButton
                      product={lastSummaryEventProduct}
                      eventDescription={lastSummaryEventDescription}
                      componentProps={{
                        variant: !isDarkMode ? 'contained' : 'text',
                        endIcon: <ArrowDropDownIcon />,
                        color: 'error',
                        // sx: {width: '100%'},
                      }}
                      onDone={onEventSummaryFetch}
                    >
                      <TruncatedText
                        style={{maxWidth: '160px'}}
                      >{`1 of ${unAckCounts}: ${lastSummaryEventDescription}`}</TruncatedText>
                    </SummaryEventButton>
                  ) : (
                    <TruncatedText
                      padding={1}
                      fontSize={isCompactMode ? 14 : 18}
                    >
                      No unacknowledged events
                    </TruncatedText>
                  )}
                </Box>
              </>
            ) : (
              <Box>
                <SummaryEventButton
                  product={lastSummaryEventProduct}
                  eventDescription={lastSummaryEventDescription}
                  componentProps={{
                    variant: !isDarkMode ? 'contained' : 'text',
                    endIcon: <ArrowDropDownIcon />,
                    //color: 'error',
                    sx: {width: '100%'},
                  }}
                  onDone={onEventSummaryFetch}
                >
                  <CampaignIcon
                    sx={{
                      fontSize: iconFontSize,
                      color: (theme) =>
                        eventsCount || eventSummaryData?.connect?.count
                          ? theme.palette.error.main
                          : undefined,
                    }}
                  />
                  <TruncatedText>Events</TruncatedText>
                </SummaryEventButton>
              </Box>
            )}
          </Box>
        </Grid>
        {/******* End Events Tab *******/}
      </Grid>
    </Box>
  );
};
