import { ShaftClearanceAsset, ShaftClearanceEmployee } from '../interfaces/ShaftClearance';
import {
  getAssetBatteryPercentage,
  getCamBatteryPercentage,
  getMc2BatteryPercentage,
  getMinerBatteryPercentage,
  getTagsBatteryPercentage,
  getWifiBatteryPercentage,
} from '../utils/commtrac-nodes';
import {useGetAssetHumanType} from './get-commtrac-node-type';

export const useGetShaftClearanceBatteryPercentage = () => {
  const getAssetHumanType = useGetAssetHumanType();
  const getShaftClearanceBatteryPercentage = (item: ShaftClearanceAsset | ShaftClearanceEmployee, isMinerorAsset: boolean) => {
    const wifiEnabled = item?.node_mac_address ? 1 : 0;
    const type = getAssetHumanType({
      commtrac_external_id: item?.node_commtrac_external_id,
      wifi_enabled: (wifiEnabled ?? null) as 0 | 1 | null,
      mc2_flag: (item?.node_mc2_flag ?? null) as 0 | 1 | null
    });
    if (
      typeof item.node_commtrac_battery_voltage !== 'number' ||
      (type === 'CAM' && !item.node_commtrac_battery_voltage)
    ) {
      return null;
    } else if (item.node_mac_address && item.node_min_voltage && item.node_max_voltage) {
      return getWifiBatteryPercentage(
        item.node_commtrac_battery_voltage,
        item.node_min_voltage,
        item.node_max_voltage
      );
    } else if (type === 'TAG') {
      return getTagsBatteryPercentage(item.node_commtrac_battery_voltage);
    } else if (type === 'CAM') {
      return getCamBatteryPercentage(item.node_commtrac_battery_voltage);
    } else if (item.node_mc2_flag === 1) {
      return getMc2BatteryPercentage(item.node_commtrac_battery_voltage);
    } else if (isMinerorAsset) {
      return getMinerBatteryPercentage(item.node_commtrac_battery_voltage);
    } else if (!isMinerorAsset) {
      return getAssetBatteryPercentage(item.node_commtrac_battery_voltage);
    }
    return null;
  };
  return getShaftClearanceBatteryPercentage;
};
