import { Box } from '@mui/material';
import { Bar, BarChart, CartesianGrid,Legend,ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { ShaftClearanceEmployeesListQuery } from '../../../interfaces/ShaftClearance';

export interface BarItem {
  data: number;
  name: string;
}

interface Props {
  params: ShaftClearanceEmployeesListQuery;
  data: BarItem[];
  onChangeFilter: (v: string, filterType: string) => void;
}

export const BarView = ({
  params,
  data,
  onChangeFilter
}: Props) => {
  const CustomLegend = ({ payload }: any) => {
    return (
      <ul style={{ display: 'flex', justifyContent: 'center', listStyle: 'none', padding: 0 }}>
        {payload.map((entry: any, index: any) => (
          <li key={`item-${index}`} style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}>
            <div style={{
              width: '16px',
              height: '16px',
              backgroundColor: entry.color,
              marginRight: '5px'
            }} />
            <span style={{ fontWeight: 'bold', color: entry.color }}>Amount</span>
          </li>
        ))}
      </ul>
    );
  };

  const CustomizedYAxisTick = ({ x, y, payload}: any) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={4}
          textAnchor="end"
          fill="#fff"
          fontSize={12}
          style={{
            width: '100px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const customizedTooltip = ({ active, payload, label }: { active?: boolean; payload?: any[]; label?: string }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{backgroundColor: '#fff', border: '2px solid #778844', padding: '0px 10px', borderRadius: '5px', color: '#000' }}>
          <p>{label}</p>
          <p style={{fontSize: '14px'}}>Amount: {payload[0].payload.data}</p>
        </div>
      );
    }
    return null;
  };

  const handleBarClick = (data: BarItem) => {
    if (!params.location && params.location !== "") {
      onChangeFilter(data.name.toLowerCase(), "location");
    } else if (!params.section_id) {
      onChangeFilter(data.name, "section_id");
    }
  };

  return (
    <Box
      height="100%"
      width="100%"
    >
      <ResponsiveContainer
        className="bar_graph"
        width="100%"
        height="80%"
      >
        <BarChart
          layout="vertical"
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis type="number" />
          <YAxis type="category" dataKey="name" tick={<CustomizedYAxisTick />} />
          <Tooltip content={customizedTooltip} />
          <CartesianGrid strokeDasharray="3 3" />
          <Legend content={<CustomLegend />} />
          <Bar
            dataKey="data"
            fill="#7db7ed"
            onClick={(data: BarItem) => handleBarClick(data)}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
