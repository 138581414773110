import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CampaignIcon from '@mui/icons-material/Campaign';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {
  Backdrop,
  Box,
  Grid,
  SxProps,
  Theme,
} from '@mui/material';
import { useMemo } from 'react';

import {useAppSelector} from '../../../hooks/redux';
import {boxStat, boxStatActive} from '../../../hooks/theme';
import {
  EventSummaryProductValue,
  EventSummaryResponse,
} from '../../../interfaces/Event';
import reduxSelectors from '../../../redux/selectors';
import { getLastSummaryEventDescription, getLastSummaryEventProduct } from '../../../utils/events';
import AccessControl from '../../common/AccessControl';
import TruncatedText from '../../common/TruncantedText';
import SummaryEventButton from '../../event-stat/button/SummaryEventButton';

export type ShaftTab =
  'employees'
  | 'assets'
  | 'events';

interface Propx {
  activeTab?: ShaftTab;
  fullTabs: boolean;
  eventsCount?: number;
  eventSummaryData?: EventSummaryResponse;
  isLoading?: boolean;
  isEventsLoading?: boolean;
  isEventSummaryLoading?: boolean;
  onChangeActiveTab?: (value: ShaftTab) => void;
  onEventSummaryFetch?: () => void;
}

export type ConnectTab =
  | 'miners'
  | 'assets'
  | 'events';

// eslint-disable-next-line complexity
export const ShaftClearanceStats = ({
  activeTab,
  fullTabs,
  eventSummaryData,
  eventsCount,
  isLoading,
  isEventsLoading,
  isEventSummaryLoading,
  onChangeActiveTab,
  onEventSummaryFetch,
}: Propx) => {
  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);
  const company = useAppSelector(({assets}) => assets.company);
  const isCompactMode = useAppSelector(reduxSelectors.app.getIsCompactMode);

  const boxStatSx: SxProps<Theme> = useMemo(() => {
    return {
      ...boxStat,
      height: isCompactMode ? (fullTabs ? 102 : 47) : 178,
      padding: isCompactMode ? '2px' : '16px 8px',
    };
  }, [isCompactMode, boxStat, fullTabs]);

  const boxStatActiveSx: SxProps<Theme> = useMemo(() => {
    return {
      ...boxStatActive,
      padding: isCompactMode ? '2px' : '16px 8px',
    };
  }, [isCompactMode, boxStatActive]);


  // Events
  /****************/
  /*
  status */
  /****************/

  const lastSummaryEventDescription = useMemo(() => {
    return getLastSummaryEventDescription(
      null,
      null,
      null,
      eventSummaryData?.shaft_clearance
    ) ?? 'Events';
  }, [eventSummaryData?.connect, eventSummaryData?.ams, eventSummaryData?.belt, eventSummaryData?.shaft_clearance, company]);

  const lastSummaryEventProduct: EventSummaryProductValue | undefined | null =
    useMemo(() => {
      return getLastSummaryEventProduct(
        null,
        null,
        null,
        eventSummaryData?.shaft_clearance,
      ) ?? undefined
    }, [eventSummaryData?.shaft_clearance, company]);

  const unAckCounts = useMemo(() => {
    if (company?.shaft_enabled ) {
      return (
        (eventSummaryData?.shaft_clearance?.unacked ?? 0)
      );
    }
  }, [eventSummaryData]);

  const totalEventCounts = useMemo(() => {
    if (company?.shaft_enabled) {
      return eventSummaryData?.shaft_clearance?.count ?? 0;
    }
  }, [eventSummaryData]);

  return (
    <Box>
      <Grid container minWidth={1200}>
        {/******* Start Miners Tab *******/}
        <AccessControl permissions={['get::/shaft-clearance/employee/grid', 'get::/shaft-clearance/employee/chart', 'get::/shaft-clearance/employee/graph']}>
          <Grid item sx={{flexGrow: 1}} position="relative">
            <Backdrop
              open={!!isLoading}
              sx={{position: 'absolute'}}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '18px',
                ...(activeTab === 'employees' ? boxStatActiveSx : boxStatSx),
              }}
              onClick={() => onChangeActiveTab?.('employees')}
            >
              Miners
            </Box>
          </Grid>
        </AccessControl>
        {/******* End Miners Tab *******/}

        {/******* Start Assets Tab *******/}
        <AccessControl permissions={['get::/shaft-clearance/asset/grid', 'get::/shaft-clearance/asset/chart', 'get::/shaft-clearance/asset/graph']}>
          <Grid item sx={{flexGrow: 1}} position="relative">
            <Backdrop
              open={!!isLoading}
              sx={{position: 'absolute'}}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '18px',
                color: 'red',
                ...(activeTab === 'assets' ? boxStatActiveSx : boxStatSx),
              }}
              onClick={() => onChangeActiveTab?.('assets')}
            >
              Assets
            </Box>
          </Grid>
        </AccessControl>
        {/******* End Assets Tab *******/}

        {/******* Start Events Tab *******/}
        <Grid item sx={{flexGrow: 1}} position="relative">
          <Backdrop
            open={!!isEventSummaryLoading || !!isEventsLoading}
            sx={{position: 'absolute'}}
          />
          <Box
            overflow="hidden"
            sx={{
              ...(activeTab === 'events' ? boxStatActiveSx : boxStatSx),
            }}
            onClick={() => onChangeActiveTab?.('events')}
          >
            {fullTabs ? (
              <>
                <CampaignIcon
                  sx={{
                    color: (theme) =>
                      eventsCount || eventSummaryData?.shaft_clearance?.count
                        ? theme.palette.error.main
                        : undefined,
                  }}
                />

                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  height="100%"
                  whiteSpace="nowrap"
                  color={(theme) =>
                    eventsCount ||
                    eventSummaryData?.connect?.count ||
                    (!!company?.ams_enabled && eventSummaryData?.ams?.count)
                      ? theme.palette.error.main
                      : undefined
                  }
                >
                  <Box display="flex" alignItems="center" gap={1} height="100%">
                    {unAckCounts !== null && totalEventCounts !== null ? (
                      <>
                        <Box>{unAckCounts}</Box>
                        <Box>/</Box>
                        <Box>{totalEventCounts}</Box>
                      </>
                    ) : (
                      <HourglassEmptyIcon fontSize="large" />
                    )}
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center">
                  {unAckCounts ? (
                    <SummaryEventButton
                      product={lastSummaryEventProduct}
                      individualProduct={["shaft_enabled"]}
                      eventDescription={lastSummaryEventDescription}
                      componentProps={{
                        variant: !isDarkMode ? 'contained' : 'text',
                        endIcon: <ArrowDropDownIcon />,
                        color: 'error',
                        // sx: {width: '100%'},
                      }}
                      onDone={onEventSummaryFetch}
                    >
                      <TruncatedText
                        style={{maxWidth: '160px'}}
                      >{`1 of ${unAckCounts}: ${lastSummaryEventDescription}`}</TruncatedText>
                    </SummaryEventButton>
                  ) : (
                    <TruncatedText
                      padding={1}
                      fontSize={isCompactMode ? 14 : 18}
                    >
                      No unacknowledged events
                    </TruncatedText>
                  )}
                </Box>
              </>
            ) : (
              <Box>
                <SummaryEventButton
                  product={lastSummaryEventProduct}
                  individualProduct={["shaft_enabled"]}
                  eventDescription={lastSummaryEventDescription}
                  componentProps={{
                    variant: !isDarkMode ? 'contained' : 'text',
                    endIcon: <ArrowDropDownIcon />,
                    //color: 'error',
                    sx: {width: '100%'},
                  }}
                  onDone={onEventSummaryFetch}
                >
                  <CampaignIcon
                    sx={{
                      color: (theme) =>
                        eventsCount || eventSummaryData?.shaft_clearance?.count
                          ? theme.palette.error.main
                          : undefined,
                    }}
                  />
                  <TruncatedText>Events</TruncatedText>
                </SummaryEventButton>
              </Box>
            )}
          </Box>
        </Grid>
        {/******* End Events Tab *******/}
      </Grid>
    </Box>
  );
};
