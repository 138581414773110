import { Box, Button, ButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ShaftClearanceBarData, ShaftClearanceEmployeesListQuery,ShaftClearancePieData} from "../../../interfaces/ShaftClearance";
import reduxSelectors from "../../../redux/selectors";
import { BarItem, BarView } from "./BarView";
import { PieItem, PieView } from "./PieView";

interface Props {
  view: string;
  pieData?: ShaftClearancePieData;
  barData?: ShaftClearanceBarData;
  params: ShaftClearanceEmployeesListQuery;
  isMinersorAsset: string;
  isLoading: boolean;
  onChangeFilter: (v: string, key: string) => void;
  onChangeChartViewMode?: (v: string) => void;
}

export const Chart = ({
  view,
  pieData,
  barData,
  params,
  isMinersorAsset,
  isLoading,
  onChangeFilter,
  onChangeChartViewMode,
}: Props) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const [viewMode, setViewMode] = useState<string>(view);
  const [graphData, setGraphData] = useState<BarItem[]>([]);
  const [locationData, setLocationData] = useState<PieItem[]>([]);
  const [sectionData, setSectionData] = useState<PieItem[]>([]);

  const generateLocationPieData = (data?: ShaftClearancePieData): PieItem[]  => {
    const result: PieItem[] = [];
    if (!data) {
      return [];
    }

    const dataByLocation = data.data.find(item => item.category_id === "location");
    dataByLocation?.categories.map((category: any) => {
      return dataByLocation.data.find((item: any) => {
        if (item.category_id === category.id && item.value > 0) {
          result.push({
            name: category.id,
            value: item.value
          })
        }
      })
    });
    return result;
  };

  const generateSectionPieData = (data?: ShaftClearancePieData): PieItem[]  => {
    const result: PieItem[] = [];
    if (!data) {
      return [];
    }

    const dataByLocation = data.data.find(item => item.category_id === "section");
    dataByLocation?.categories.map((category: any) => {
      return dataByLocation.data.find((item: any) => {
        if (item.category_id === category.id && item.value > 0) {
          result.push({
            name: category.label,
            value: item.value,
          })
        }
      })
    });

    return result;
  };

  const generateBarData = (data?: ShaftClearanceBarData): BarItem[]  => {
    const result: BarItem[] = [];
    if (!data) {
      return [];
    } else if (!params.location && !params.section_id && view === 'bar') {
      const dataByLocation = pieData?.data.find(item => item.category_id === "location");
      dataByLocation?.categories.map((category: any) => {
        return dataByLocation.data.find((item: any) => {
          if (item.category_id === category.id && item.value > 0) {
            result.push({
              name: category.label,
              data: item.value
            })
          }
        })
      });
    } else {
      data?.categories.map((category: any) => {
        return data.data.find((item: any) => {
          if (item.category_id === category.id && item.value > 0) {
            result.push({
              data: item.value,
              name: category.label
            })
          }
        })
      });
    }
    return result;
  };

  const getChartTitle = () => {
    if (!params.location && !params.section_id) {
      return (
        <Box>
          {`Amount of ${isMinersorAsset} per Location`}
          <Box sx={{ fontSize: '16px' }}>&nbsp;</Box>
        </Box>
      )
    } else if (!params.location && params.section_id) {
      const zone = assets.zones.find((zone) => zone.id === params.section_id);
      return (
        <Box>
          {`Amount of ${isMinersorAsset} per CN in Section`}
          <Box sx={{fontSize: '16px'}}>Section: {zone?.name}</Box>
        </Box>
      )
    } else if (params.location && !params.section_id) {
      return (
        <Box>
          {`Amount of ${isMinersorAsset} per Section`}
          <Box sx={{fontSize: '16px'}}>Location: {!params.section_id && (params.location)}</Box>
        </Box>
      )
    } else if (params.location && params.section_id) {
      const zone = assets.zones.find((zone) => zone.id === params.section_id);
      return (
        <Box>
          {`Amount of ${isMinersorAsset} per CN in Section`}
          <Box sx={{fontSize: '16px'}}>Section: {zone?.name}</Box>
        </Box>
      )
    }
  }

  useEffect(() => {
    setViewMode(view);
  }, [view])

  useEffect(() => {
    if (view === 'pie') {
      setSectionData(generateSectionPieData(pieData));
      setLocationData(generateLocationPieData(pieData));
    } else if (view === 'bar') {
      setGraphData(generateBarData(barData));
    }
  }, [params, barData, pieData])

  return (
    <Box
      className="chart"
      sx={{
        height: `100%`,
        '& .pie_chart': {
          minHeight: '400px',
          position: 'absolute',
          top: '55%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '1'
        },
        '& .bar_graph': {
          position: 'absolute',
        },
      }}
    >
      {!params?.section_id && (
        <Box paddingLeft={2}>
          <ButtonGroup>
            <Button
              size="small"
              variant={viewMode === 'pie' ? 'contained' : 'outlined'}
              onClick={() => {
                setViewMode('pie');
                onChangeChartViewMode?.('pie');
              }}
            >
              Pie
            </Button>

            <Button
              size="small"
              variant={viewMode === 'bar' ? 'contained' : 'outlined'}
              onClick={() => {
                setViewMode('bar');
                onChangeChartViewMode?.('bar');
              }}
            >
              Bar
            </Button>
          </ButtonGroup>
        </Box>
      )}
      <Box
        style={{
          fontSize: '24px',
          textAlign: 'center',
          marginBottom: '10px'
        }}
      >
        {getChartTitle()}
      </Box>
      {viewMode === 'pie' && !isLoading && (
        <PieView
          locationData={locationData}
          sectionData={sectionData}
          location={params?.location}
          onChangeFilter={(v, filterType) => onChangeFilter(v, filterType)}
        />
      )}
      {viewMode === 'bar' && (
        <BarView
          params={params}
          data={graphData}
          onChangeFilter={(v, filterType) => onChangeFilter(v, filterType)}
        />
      )}
    </Box>
  )
};
