import BarChartIcon from '@mui/icons-material/BarChart';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Crop54Icon from '@mui/icons-material/Crop54';
import PrintIcon from '@mui/icons-material/Print';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useSelector } from 'react-redux';

import API, { getMessagesFromApiError } from '../../../api/axios';
import { apiBaseUrl } from '../../../api/urls';
import {useGetAssetHumanType} from '../../../hooks/get-commtrac-node-type';
import { useGetShaftClearanceBatteryPercentage } from '../../../hooks/get-shaft-clearance-node-battery-percentage';
import { useAppSelector } from '../../../hooks/redux';
import { useRefreshInterval } from '../../../hooks/refreshInterval';
import usePrevious from '../../../hooks/usePrevious';
import {ExportField} from '../../../interfaces/Export';
import { ShaftClearanceBarData, ShaftClearanceEmployee, ShaftClearanceEmployeeListResponse, ShaftClearanceEmployeesListQuery,ShaftClearancePieData } from '../../../interfaces/ShaftClearance';
import reduxSelectors from '../../../redux/selectors';
import { AutoRefreshSelect } from '../../common/AutoRefreshSelect';
import { BatteryIcon } from '../../common/BatteryIcon';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {ResizableColumns} from '../../common/ResizableColumns';
import { Section, ShaftSelect } from '../../selectors/ShaftSelect';
import { ShiftSelect } from '../../selectors/ShiftSelect';
import { Chart } from './Chart';
import { ShaftDateSelect } from './ShaftDateSelect';

export interface ShaftClearanceEmployeesData {
  params: ShaftClearanceEmployeesListQuery;
  selectedIds: string[] | null;
  viewMode: string;
  refreshInterval: number | null | undefined;
  shownFields: {
    all?: string[];
  };
  grid: {
    pageSize: number;
    page: number;
  };
  exportFields: ExportField[];
}

interface Props {
  value: ShaftClearanceEmployeesData;
  onChange?: (value?: ShaftClearanceEmployeesData) => void;
  onLoading?: (v: boolean) => void;
}

export const getShaftClearanceEmployeesData = (): any => ({
  params: {
    page: 0,
    limit: 25,
    location: undefined,
    section_id: undefined,
    shift_id: undefined,
    date: undefined,
  },
  viewMode: "pie",
  refreshInterval: 60000,
  selectedIds: [],
  shownFields: {
    all: DEFAULT_SHOWN_FIELDS,
  },
  grid: {
    page: 0,
    pageSize: 25,
  },
  exportFields: [
    {field: "node_commtrac_battery_voltage", hidden: false, label: "Battery"},
    {field: "ah_first_name", hidden: false, label: "First Name"},
    {field: "ah_last_name", hidden: false, label: "Last Name"},
    {field: "node_name", hidden: false, label: "Name"},
    {field: "node_commtrac_external_id", hidden: false, label: "Network ID"},
    {field: "type", hidden: false, label: "Type"},
    {field: "node_commtrac_strongest_cn_name", hidden: false, label: "Strongest Node"},
    {field: "node_commtrac_date", hidden: false, label: "Timestamp"},
    {field: "ah_shift_id", hidden: false, label: "Shift"},
    {field: "node_commtrac_current_zone_id", hidden: false, label: "Section"},
    {field: "node_commtrac_on_surface", hidden: false, label: "Pos"},
  ]
});

const DEFAULT_SHOWN_FIELDS = [
  'select',
  'node_commtrac_battery_voltage',
  'ah_first_name',
  'ah_last_name',
  'node_name',
  'node_commtrac_external_id',
  'type',
  'node_commtrac_strongest_cn_name',
  'node_commtrac_date',
  'ah_shift_id',
  'node_commtrac_current_zone_id',
  'node_commtrac_on_surface',
];

export const ShaftClearanceEmployees = ({
  value,
  onChange,
  onLoading
}: Props) => {
  const getShaftClearanceBatteryPercentage =
    useGetShaftClearanceBatteryPercentage();
  const [config, setConfig] = useState<ShaftClearanceEmployeesData>(value);

  const assets = useSelector(reduxSelectors.assets.getAssets);
  const minerAddressMask = useAppSelector(
    ({assets}) => assets.constants?.miner.address_mask
  );

  const [fetchedSectionsFilterData, setFetchedSectionsData] = useState<Section[] | []>([]);
  const [fetchedSectionsFilterErrors, setFetchedSectionsFilterErrors] = useState<string[]>([]);
  const [fetchedSectionsFilterInProgress, setFetchedSectionsFilterInProgress] = useState(false);

  const fetchSectionsFilter = useCallback(
    async () => {
      setFetchedSectionsFilterInProgress(true);
      setFetchedSectionsFilterErrors([]);
      onLoading?.(true);

      try {
        const params = {
          location: config.params.location,
        };

        const resp = await API.get<ShaftClearancePieData>(`${apiBaseUrl}/shaft-clearance/employee/chart`, {
          params
        });

        const dataByLocation = resp?.data.data.find(item => item.category_id === "section");
        const sections: any[] = [];

        dataByLocation?.categories.map((category: any) => {
          return dataByLocation.data.find((item: any) => {
            if (item.category_id === category.id && item.value > 0) {
              sections.push({
                name: category.label,
                value: item.value,
              })
            }
          })
        });

        const newSections = assets.zones
          .filter(zone => sections.some((it) => it.name === zone.name))
          .map(zone => ({
            id: zone.id.toString(),
            name: zone.name
          }));

          setFetchedSectionsData(newSections);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedSectionsFilterErrors(messages);
      } finally {
        setFetchedSectionsFilterInProgress(false);
      }
    },
  [config.params.location]);

  const [fetchedEmployeesData, setFetchedEmployeesData] = useState<ShaftClearanceEmployeeListResponse>();
  const [fetchedEmployeesErrors, setFetchedEmployeesErrors] = useState<string[]>([]);
  const [fetchedEmployeesInProgress, setFetchedEmployeesInProgress] = useState(false);

  const fetchEmployees = useCallback(
    async () => {
      setFetchedEmployeesInProgress(true);
      setFetchedEmployeesErrors([]);
      onLoading?.(true);

      try {
        const params = {
          ...config.params,
          date: config.params.date ? dayjs(config.params.date).format("YYYY-MM-DD hh:mm:ss") : undefined,
        };
        const resp = await API.get<ShaftClearanceEmployeeListResponse>(`${apiBaseUrl}/shaft-clearance/employee/grid`, {
          params
        });
        setFetchedEmployeesData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedEmployeesErrors(messages);
      } finally {
        onLoading?.(false);
        setFetchedEmployeesInProgress(false);
      }
    },
  [config.params]);

  const [refreshInterval, setRefreshInterval] = useRefreshInterval(
    fetchEmployees,
    config.refreshInterval
  );

  useEffect(() => {
    onChange?.(
      update(value, {
        refreshInterval: {
          $set: refreshInterval,
        },
      })
    );
  }, [refreshInterval]);

  const [fetchedEmployeesPieData, setFetchedEmployeesPieData] = useState<ShaftClearancePieData>();
  const [fetchedEmployeePieErrors, setFetchedEmployeesPieErrors] = useState<string[]>([]);
  const [fetchedEmployeesPieInProgress, setFetchedEmployeesPieInProgress] = useState(false);
  const fetchEmployeesPie = useCallback(async () => {
    setFetchedEmployeesPieInProgress(true);
    setFetchedEmployeesPieErrors([]);
    onLoading?.(true);

    try {
      const params = {
        location: config.params.location,
        section_id: config.params.section_id,
        shift_id: config.params.shift_id,
        date: config.params.date,
      };

      const resp = await API.get<ShaftClearancePieData>(`${apiBaseUrl}/shaft-clearance/employee/chart`, {
        params
      });
      setFetchedEmployeesPieData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedEmployeesPieErrors(messages);
    } finally {
      onLoading?.(false);
      setFetchedEmployeesPieInProgress(false);
    }
  }, [config.params, config.viewMode]);

  const [fetchedEmployeesBarData, setFetchedEmployeesBarData] = useState<ShaftClearanceBarData>();
  const [fetchedEmployeeBarErrors, setFetchedEmployeesBarErrors] = useState<string[]>([]);
  const [fetchedEmployeesBarInProgress, setFetchedEmployeesBarInProgress] = useState(false);
  const fetchEmployeesBar = useCallback(async () => {
    setFetchedEmployeesBarInProgress(true);
    setFetchedEmployeesBarErrors([]);
    onLoading?.(true);

    try {
      const params = {
        location: config.params.location,
        section_id: config.params.section_id,
        shift_id: config.params.shift_id,
        date: config.params.date,
      };

      const resp = await API.get<ShaftClearanceBarData>(`${apiBaseUrl}/shaft-clearance/employee/graph`, {
        params
      });
      setFetchedEmployeesBarData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedEmployeesBarErrors(messages);
    } finally {
      onLoading?.(false);
      setFetchedEmployeesBarInProgress(false);
    }
  }, [config.params, config.viewMode]);

  const locationsList = [
    {id: 'surface', name: "Surface"},
    {id: 'underground', name: "Underground"}
  ]

  /**
   * Data Grid
   */
  const getAssetHumanType = useGetAssetHumanType();
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = useMemo(() => fetchedEmployeesData?.items ?? [], [fetchedEmployeesData]);
  const columns: DataGridColumn<ShaftClearanceEmployee>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Checkbox
            color="primary"
            disabled={rows.length === 0}
            checked={selectedItems.length > 0 && selectedAll}
            indeterminate={selectedItems.length > 0 && !selectedAll}
            onChange={() => toggleSelectAllItems()}
          />
        </Box>
      ),
      renderCell: ({row}) => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Checkbox
            color="primary"
            checked={selectedItems.includes(String(row.node_id))}
            onChange={() => toggleSelectItem(String(row.node_id))}
          />
        </Box>
      ),
    },
    {
      field: 'node_commtrac_battery_voltage',
      headerName: 'Battery',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="Low Battery">
          <BatteryChargingFullIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      valueGetter: ({row}) => getShaftClearanceBatteryPercentage(row, true),
      renderCell: ({value}) => {
        if (typeof value === 'number') {
          return (
            <Tooltip title={`${value}%`}>
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <BatteryIcon value={value} />
              </Box>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title="Unknown">
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <QuestionMarkIcon />
              </Box>
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'ah_first_name',
      headerName: 'First Name',
      sortable: true,
      valueGetter: ({row}) => row.ah_first_name,
    },
    {
      field: 'ah_last_name',
      headerName: 'Last Name',
      sortable: true,
      valueGetter: ({row}) => row.ah_last_name,
    },
    {
      field: 'node_name',
      headerName: 'Name',
      sortable: true,
      valueGetter: ({row}) => row.node_name,
    },
    {
      field: 'node_commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.node_mac_address) {
          return row.node_mac_address
        } else if (row.node_commtrac_external_id) {
          return minerAddressMask // eslint-disable-next-line
            ? // eslint-disable-next-line no-bitwise
              row.node_commtrac_external_id & minerAddressMask
            : null;
        }
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      renderCell: ({row}) => {
        const wifiEnabled = row?.node_mac_address ? 1 : 0;
        const type = getAssetHumanType({
          commtrac_external_id: row?.node_commtrac_external_id,
          wifi_enabled: (wifiEnabled ?? null) as 0 | 1 | null,
          mc2_flag: (row?.node_mc2_flag ?? null) as 0 | 1 | null
        });
        return (
          <Box>
            {type}
          </Box>
        )
      }
    },
    {
      field: 'node_commtrac_strongest_cn_name',
      headerName: 'Strongest Node',
      sortable: true,
      valueGetter: ({row}) => row.node_commtrac_strongest_cn_name,
    },
    {
      field: 'node_commtrac_date',
      headerName: 'Timestamp',
      sortable: true,
      valueGetter: ({row}) => row.node_commtrac_date
        ? dayjs(row.node_commtrac_date).format('YYYY-MM-DD HH:mm:ss')
        : '-',
    },
    {
      field: 'ah_shift_id',
      headerName: 'Shift',
      sortable: true,
      valueGetter: ({row}) => assets.shifts.find((i) => i.id === row.ah_shift_id)?.name ?? null,
    },
    {
      field: 'node_commtrac_current_zone_id',
      headerName: 'Section',
      sortable: true,
      valueGetter: ({row}) => assets.zones.find((i) => i.id === row.node_commtrac_current_zone_id)?.name ?? null,
    },
    {
      field: 'node_commtrac_on_surface',
      headerName: 'Position',
      sortable: true,
      valueGetter: ({row}) => row.node_commtrac_on_surface === '1',
      renderCell: ({row}) => {
        if (row.node_commtrac_on_surface === '1') {
          return (
            <Tooltip title="Surface">
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <BarChartIcon color="success" />
              </Box>
            </Tooltip>
          );
        } else if (row.node_commtrac_on_surface === '0') {
          return (
            <Tooltip title="Underground">
              <Box sx={{position: 'relative', left: 4, top: 4}}>
                <Crop54Icon color="success" />
              </Box>
            </Tooltip>
          );
        }
      },
    },
  ];

  const shownFields = useMemo(() => {
    return config.shownFields.all;
  }, [value]);

  useEffect(() => {
    const excludedFields = [
      'ah_first_name',
      'ah_last_name',
      'node_name',
      'node_commtrac_external_id',
      'node_commtrac_strongest_cn_name',
      'node_commtrac_date',
      'ah_shift_id',
      'node_commtrac_current_zone_id',
      'node_commtrac_on_surface',
    ];
    onChange?.(
      update(value, {
        exportFields: {
          $set: columns
            .filter(
              (col) => !!col.headerName && !excludedFields.includes(col.field)
            )
            .map((col) => ({
              field: col.field,
              label: col.headerName,
              hidden: !shownFields?.includes(col.field),
            })),
        },
      })
    );
  }, [shownFields]);

  const handleChangeShownFields = (fields: string[]) => {
    onChange?.(
      update(value, {
        shownFields: {
          all: {$set: fields},
        },
      })
    );
  };

  // Multiple Select
  const selectedItems = config.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((item) => config.selectedIds?.includes(String(item.node_id))),
    [rows, config.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const selectAll = () => {
    onChange?.(
      update(value, {
        selectedIds: {
          $set: rows?.map((item) => String(item.node_id)) ?? [],
        },
      })
    );
  };

  const unselectAll = () => {
    onChange?.(
      update(value, {
        selectedIds: {
          $set: [],
        },
      })
    );
  };

  const toggleSelectItem = (id: string) => {
    if (config.selectedIds?.includes(id)) {
      onChange?.(
        update(value, {
          selectedIds: {
            $set: config.selectedIds.filter((i) => i !== id),
          },
        })
      );
    } else {
      onChange?.(
        update(value, {
          selectedIds: {
            $set: [...(config.selectedIds ?? []), id],
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  useEffect(() => {
    fetchEmployeesPie();
    fetchEmployeesBar();
    fetchEmployees();
  }, [config.params, config.viewMode]);

  useEffect(() => {
    fetchSectionsFilter();
  }, [config.params.location, config.viewMode]);

  useEffect(() => {
    setConfig(value);
  }, [value]);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Backdrop open={fetchedEmployeesPieInProgress || fetchedEmployeesInProgress || fetchedEmployeesBarInProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedEmployeesErrors.map((error, idx) => (
        <Alert
          key={`error-se-${idx}`}
          severity="error"
          onClose={() => fetchEmployees()}
        >
          {error}
        </Alert>
      ))}
      {fetchedEmployeePieErrors.map((error, idx) => (
        <Alert
          key={`error-sp-${idx}`}
          severity="error"
          onClose={fetchEmployeesPie}
        >
          {error}
        </Alert>
      ))}
      {fetchedEmployeeBarErrors.map((error, idx) => (
        <Alert
          key={`error-sb-${idx}`}
          severity="error"
          onClose={() => fetchEmployeesBar()}
        >
          {error}
        </Alert>
      ))}
      {fetchedSectionsFilterErrors.map((error, idx) => (
        <Alert
          key={`error-sb-${idx}`}
          severity="error"
          onClose={() => fetchSectionsFilter()}
        >
          {error}
        </Alert>
      ))}
      <Box p={2} minWidth={900}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height={40}
          gap={2}
        >
          <Box
            display="flex"
            flexGrow={1}
            minWidth={1000}
            gap={1}
          >
            <ShaftSelect
              items={locationsList}
              value={config.params.location}
              size="small"
              nullLabel="All Locations"
              label="Location"
              fullWidth
              onChange={(v) => {
                const location = v ?? undefined;
                onChange?.(
                  update(value, {
                    params: {
                      location: {
                        $set: location,
                      },
                      section_id: { $set: undefined },
                      shift_id: { $set: undefined },
                    },
                    viewMode: { $set: 'pie'}
                  })
                );
              }}
            />
            <ShaftSelect
              items={!fetchedSectionsFilterInProgress ? fetchedSectionsFilterData : []}
              value={config.params.section_id?.toString()}
              size="small"
              nullLabel="All Sections"
              label="Section"
              fullWidth
              onChange={(v) => {
                const sectionId = v ? +v : undefined;

                const updateConfig = {
                  params: {
                    section_id: {
                      $set: sectionId,
                    },
                    shift_id: {$set: undefined},
                  },
                  ...(sectionId === undefined
                    ? {
                        viewMode: {
                          $set: 'pie',
                        },
                      }
                    : {
                        viewMode: {
                          $set: 'bar',
                        },
                      }
                  ),
                };
                onChange?.(update(value, updateConfig));
              }}
            />
            <ShiftSelect
              value={config.params.shift_id}
              nullLabel="All Shifts"
              label="Shift"
              size="small"
              fullWidth
              onChange={(v) => {
                const shiftId = v ? +v : undefined;
                onChange?.(
                  update(value, {
                    params: {
                      shift_id: {
                        $set: shiftId,
                      },
                    },
                  })
                );
              }}
            />
            <ShaftDateSelect
              value={config.params.date ? dayjs(config.params.date).toDate() : undefined}
              renderInput={{
                label: 'Date',
                size: 'small',
                fullWidth: true,
              }}
              onChange={(v) => {
                const date = v ? v : undefined;
                const updateConfig = {
                  params: {
                    date: {
                      $set: date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : undefined,
                    },
                  },
                };
                onChange?.(update(value, updateConfig));
              }}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            width="100%"
            height="100%"
          >
            <ButtonGroup>
              <Button onClick={() => fetchEmployees()}>
                <Tooltip title="Refresh">
                  <RefreshIcon />
                </Tooltip>
              </Button>

              <AutoRefreshSelect
                value={value?.refreshInterval ? value?.refreshInterval : null}
                onChange={setRefreshInterval}
              />

              <Button onClick={() => {}}>
                <PrintIcon
                  onClick={() => dataGridRef.current?.printTable()}
                />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
      <ResizableColumns
        left={
          <Box
            sx={{
              height: '100%',
            }}
          >
            <Chart
              view={config.viewMode}
              pieData={fetchedEmployeesPieData}
              barData={fetchedEmployeesBarData}
              params={config.params}
              isMinersorAsset="miners"
              isLoading={fetchedEmployeesBarInProgress || fetchedEmployeesPieInProgress}
              onChangeFilter={(v: string, key: string) => {
                let val = undefined;
                if (key === "location") {
                  val = v;
                } else {
                  val = assets.zones.find((i) => i.name === v)?.id ?? null;
                }

                const updates = {
                  params: {
                    [key]: { $set: val },
                  },
                  viewMode: {
                    $set: key === "location" ? 'pie' : 'bar',
                  },
                };
                onChange?.(
                  update(value, updates)
                );
              }}
              onChangeChartViewMode={(v: string) => {
                onChange?.(
                  update(value, {
                    viewMode: {
                      $set: v,
                    }
                  })
                );
              }}
            />
          </Box>
        }
      >
        <Box
          className="data_table_css"
          height="100%"
          sx={{
            '& .MuiTableContainer-root': {
              zIndex: '10',
            }
          }}
        >
          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            size="small"
            pagination
            page={config.grid.page}
            pageSize={config.grid.pageSize}
            loading={fetchedEmployeesInProgress}
            shownFields={shownFields}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            footerStart={
              selectedItems.length ? (
                <Box display="flex" alignItems="center" gap={3}>
                  <Box
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                    height="100%"
                    whiteSpace="nowrap"
                  >
                    {selectedItems.length} selected
                  </Box>
                </Box>
              ) : null
            }
            onShownFieldsChange={handleChangeShownFields}
            onPageChange={(v) => {
              onChange?.(
                update(value, {
                  grid: {
                    page: {
                      $set: v,
                    },
                  },
                })
              );
            }}
            onPageSizeChange={(v) => {
              onChange?.(
                update(value, {
                  grid: {
                    pageSize: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
        </Box>
      </ResizableColumns>
    </Box>
  );
};
