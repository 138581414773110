import {Autocomplete, TextField, TextFieldProps} from '@mui/material';
import isNil from 'lodash/isNil';
import {useMemo} from 'react';

export interface Section {
  id: string;
  name: string;
}

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'sx'
> & {
  items: Section[] | [];
  value?: string | null;
  nullLabel?: string;
  onChange?: (value?: string | null) => void;
};

export const ShaftSelect = ({
  items,
  value,
  disabled,
  fullWidth,
  size,
  nullLabel,
  onChange,
  ...props
}: Props) => {
  const nullValue = "all";

  const options = useMemo(() => {
    if (nullLabel) {
      return [
        {
          id: nullValue,
          name: nullLabel,
        },
        ...items,
      ];
    }
    return items;
  }, [items, nullLabel]);

  const selectedOptionId = isNil(value) ? nullValue : value;
  const selectedOption = options.find((i) => i.id === selectedOptionId) ?? null;

  return disabled ? (
    <TextField
      {...props}
      value={options.find((i) => i.id === value)?.name ?? ''}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
    />
  ) : (
    <Autocomplete
      value={selectedOption ?? null}
      disabled={disabled}
      fullWidth={fullWidth}
      options={options}
      size={size}
      disableClearable={selectedOptionId === nullValue}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionLabel={(o) => o.name ?? ''}
      renderInput={(params) => (
        <TextField label="Section" {...props} {...params} />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      onChange={(_, option) => {
        console.log("calling shaft select");
        const v =
          !option || isNil(option.id) || option.id === nullValue
            ? null
            : option.id;
        onChange?.(v);
      }}
    />
  );
};
