import dayjs from 'dayjs';
import _ from 'lodash';

import {eventsFiltersConfig} from '../components/selectors/EventTypeSelect';
import {EventSummaryProductValue} from '../interfaces/Event';
import {ALARM_MODULE_SOUNDS} from './alarm-modules';
import {AMS_SOUNDS} from './ams';
import {
  ASSET_EVENT_COLORS,
  ASSET_EVENT_SOUNDS,
  MINER_EVENT_COLORS,
  MINER_EVENT_SOUNDS,
} from './commtrac-nodes';
import {tweakLabelForMiner} from './macAddress';
import {CN_EVENT_COLORS, CN_EVENT_SOUNDS} from './nodes';
import {SAFEYE_NANO_EVENT_SOUNDS} from './safeyeNodes';

export const getEventDescription = (
  template: string,
  textReplacement: string[]
) => {
  if (!template) {
    return null;
  }

  if (!textReplacement || !textReplacement.length) {
    return tweakLabelForMiner(template);
  }
  return tweakLabelForMiner(
    template.replace(/(%s)\d{0,}/gim, (match) => {
      if (match === '%s') {
        return textReplacement[0];
      }
      const [, index] = match.split('%s');

      return textReplacement[+index - 1] ? textReplacement[+index - 1] : '';
    })
  );
};

export const getLastSummaryEventDescription = (
  product: EventSummaryProductValue | undefined | null,
  ams: EventSummaryProductValue | undefined | null,
  belt: EventSummaryProductValue | undefined | null,
  shaft_clearance: EventSummaryProductValue | undefined | null
) => {
  const productRecentDate = product?.recent
    ? dayjs(product.recent.date).valueOf()
    : null;
  const amsRecentDate = ams?.recent ? dayjs(ams.recent.date).valueOf() : null;
  const beltRecentDate = belt?.recent
    ? dayjs(belt.recent.date).valueOf()
    : null;
  const shaftClearanceRecentDate = shaft_clearance?.recent
    ? dayjs(shaft_clearance.recent.date).valueOf()
    : null;

  const recentEntries = [
    {label: 'product', date: productRecentDate},
    {label: 'ams', date: amsRecentDate},
    {label: 'belt', date: beltRecentDate},
    {label: 'shaft_clearance', date: shaftClearanceRecentDate},
  ];

  const validRecentEntries = recentEntries.filter(
    (entry) => entry.date !== null
  );

  if (validRecentEntries.length === 0) {
    return 'No recent events available.';
  }

  const mostRecentEntry = validRecentEntries.reduce((prev, current) => {
    const prevDate = prev.date ?? -Infinity;
    const currentDate = current.date ?? -Infinity;
    return prevDate > currentDate ? prev : current;
  });

  // Return a description based on the most recent entry
  let result: any = null;
  switch (mostRecentEntry.label) {
    case 'product':
      result = product;
      break;
    case 'ams':
      result = ams;
      break;
    case 'belt':
      result = belt;
      break;
    case 'shaft_clearance':
      result = shaft_clearance;
      break;
  }

  const date = dayjs(result?.recent?.date).format('YYYY-MM-DD HH:mm:ss');
  const desc = getEventDescription(
    result?.recent?.text_template ?? '',
    result?.recent?.text_replacements ?? ['']
  );
  return `${date} - ${desc}`;
};

export const getLastSummaryEventProduct = (
  product: EventSummaryProductValue | undefined | null,
  ams: EventSummaryProductValue | undefined | null,
  belt: EventSummaryProductValue | undefined | null,
  shaft_clearance: EventSummaryProductValue | undefined | null
): EventSummaryProductValue | undefined | null => {
  const productRecentDate = product?.recent
    ? dayjs(product.recent.date).valueOf()
    : null;
  const amsRecentDate = ams?.recent ? dayjs(ams.recent.date).valueOf() : null;
  const beltRecentDate = belt?.recent
    ? dayjs(belt.recent.date).valueOf()
    : null;
  const shaftClearanceRecentDate = shaft_clearance?.recent
    ? dayjs(shaft_clearance.recent.date).valueOf()
    : null;

  const recentEntries = [
    {label: 'product', date: productRecentDate},
    {label: 'ams', date: amsRecentDate},
    {label: 'belt', date: beltRecentDate},
    {label: 'shaft_clearance', date: shaftClearanceRecentDate},
  ];

  const validRecentEntries = recentEntries.filter(
    (entry) => entry.date !== null
  );

  if (validRecentEntries.length === 0) {
    return undefined;
  }

  const mostRecentEntry = validRecentEntries.reduce((prev, current) => {
    const prevDate = prev.date ?? -Infinity;
    const currentDate = current.date ?? -Infinity;
    return prevDate > currentDate ? prev : current;
  });

  // Return the appropriate product based on the most recent entry
  switch (mostRecentEntry.label) {
    case 'product':
      return product;
    case 'ams':
      return ams;
    case 'belt':
      return belt;
    case 'shaft_clearance':
      return shaft_clearance;
    default:
      return undefined; // Return undefined as a fallback
  }
};

export const getCompanyProductStatusByType = (typeToFind: number) => {
  return _.chain(eventsFiltersConfig)
    .filter(
      (group) => _.some(group.ids, {type: typeToFind}) // Check if the group contains the type
    )
    .map((group) => ({
      type: typeToFind,
      product: group.product || '', // Get the product if exists
    }))
    .find() // Get the first matching object
    .value();
};

export const CONNECT_EVENT_SOUNDS: {
  [key: number]: string | null;
} = {
  ...CN_EVENT_SOUNDS,
  ...ASSET_EVENT_SOUNDS,
  ...MINER_EVENT_SOUNDS,
  ...ALARM_MODULE_SOUNDS,
  ...AMS_SOUNDS,
};

export const CONNECT_EVENT_COLORS: {
  [key: number]: string | null;
} = {
  ...CN_EVENT_COLORS,
  ...ASSET_EVENT_COLORS,
  ...MINER_EVENT_COLORS,
};

export const HAZARD_AI_EVENT_SOUNDS: {[key: number]: string} = {
  ...SAFEYE_NANO_EVENT_SOUNDS,
};
