import {
  Checkbox,
  InputProps,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@mui/material';
import _ from 'lodash';
import {useEffect, useMemo, useRef, useState} from 'react';

import {useAppSelector} from '../../../hooks/redux';
import {CompanyProduct} from '../../../interfaces/Company';
import {eventBaseTypesSelect} from '../../../redux/assets/selectors';
import {tweakLabelForMiner} from '../../../utils/macAddress';

interface FilterOptionGroup {
  groupLabel: EventTypes;
  group: number,
  ids: Array<{type: number}>;
  product?: CompanyProduct
}

interface Option {
  label: string;
  type: number;
  group_type: string | null;
  group: number;
  is_group: boolean;
}

type EventTypes = "Shaft Clearance Events";

export const eventsFiltersConfig: FilterOptionGroup[] = [
  {
    groupLabel: 'Shaft Clearance Events',
    ids: [
      {type: 501},
      {type: 503},
    ],
    product: 'shaft_enabled',
    group: 1,
  },
];

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'SelectProps'
  | 'sx'
> & {
  value?: string[];
  onChange: (v: string[], isNonSelected: boolean) => void;
};

export const EventTypeSelect = ({
  ...props
}: Props) => {
  const company = useAppSelector(({assets}) => assets.company);
  const eventBaseTypes = useAppSelector(eventBaseTypesSelect);
  const prevCompanyRef = useRef(company);

  const eventsFilterOptions = useMemo(() => {
    props.onChange([], true);
    return eventsFiltersConfig
      .filter((it) => {
        return (
          (company?.commtrac_enabled && company.shaft_enabled &&
          ["Shaft Clearance Events"].includes(it?.groupLabel))
        );
      })
      .reduce((acc: any[], {ids, groupLabel, group}) => {
        const optionsWithChecked = _.intersectionBy(eventBaseTypes, ids, 'type').map(option => {
          const groupType = `${group}_${option.type}`;
          return {
            ...option,
            type: option.type,
            group_type: groupType,
          };
        });
        acc.push({
          label: groupLabel,
          options: optionsWithChecked,
          checked: false,
          group: group
        });
        return acc;
      }, []);
  }, [eventBaseTypes, eventsFiltersConfig, company]);

  const selectOptions = useMemo((): Option[] => {
    return eventsFilterOptions.flatMap(group =>
      group.options.map((option: any) => ({
        label: option.name,
        type: option.type,
        group_type: option.group_type,
        group: group.group,
        is_group: false
        }))
      ).concat(eventsFilterOptions.map(group => ({
        label: group.label,
        type: group.group, // No specific type for the group itself
        group_type: null, // No specific group_type for the group itself
        group: group.group,
        is_group: true // This indicates that it's the group
      })).concat([{
        label: "All Events",
        type: 0,
        group_type: null,
        group: 0,
        is_group: true
      }])
    );
  }, [eventsFilterOptions]);

  const areAllGroupItemsChecked = (group: number): boolean => {
    const options = selectOptions.filter((opt: Option) => props.value?.includes(opt.type.toString()));
    const groupOptions = selectOptions.filter(option => option.group === group && !option.is_group);
    return groupOptions.length > 0 && groupOptions.every(option => options.some(sel => sel.type === option.type));
  };

  const allGroupsChecked = (): boolean => {
    const options = selectOptions.filter((opt: Option) => props.value?.includes(opt.type.toString()));
    const checkedGroupOpt = selectOptions.filter((opt: Option) => opt.is_group && areAllGroupItemsChecked(opt.group));
    return options.length + checkedGroupOpt.length + 1 === selectOptions.length
  };

  const [selected, setSelected] = useState<Option[]>(props.value && props.value.length !== 0
    ?  [
      ...selectOptions.filter((opt: Option) => props.value?.includes(opt.type.toString())),
      ...selectOptions.filter((opt: Option) => opt.is_group && areAllGroupItemsChecked(opt.group)),
      ...selectOptions.filter((opt: Option) => opt.type === 0 && allGroupsChecked()),
      ]
    : selectOptions);

  useEffect(() => {
    if (selected.length !== 0) {
      if (_.includes(getSelectTypes(), 0)) {
        props.onChange([], false);
      } else {
        props.onChange(selected.filter((item) => !item.is_group).map((opt) => opt.type.toString()), false);
      }
    } else {
      props.onChange([], true);
    }
  }, [selected]);

  useEffect(() => {
    if (!_.isEqual(prevCompanyRef.current, company)) {
      setSelected([]);
    }
    prevCompanyRef.current = company;
  }, [company]);

  const getSelectTypes = () => {
    return selected.map(group => group.type)
  }

  const getMenuItemOption = (value: number): Option | undefined => {
    return _.find(selectOptions, ((item) => item.type === value));
  }

  const isGroupMenuItem = (value: number): boolean => {
    return getMenuItemOption(value)?.is_group ?? false;
  }

  const isExistingorNot = (value: number): boolean => {
    return _.findIndex(selected, getMenuItemOption(value)) >= 0;
  }

  const handleClose = () => {
    if (_.includes(getSelectTypes(), 0)) {
      props.onChange([], false);
    } else if (selected.length !== 0) {
      props.onChange(selected.filter((item) => !item.is_group).map((opt) => opt.type.toString()), false);
    } else if (selected.length === 0) {
      props.onChange([], true);
    }
  }

  const handleChange: InputProps['onChange'] = (event: any) => {
    const val =_.last(event.target.value);

    if (val) {
      const selectedVal = parseInt(val.toString());
      const isSelectAll = selectedVal === 0;
      const selectMenuItem = getMenuItemOption(selectedVal);
      const isSelected = isExistingorNot(selectedVal);

      if (isSelected) {
        if (isSelectAll) { // when clicking "All Events"
          setSelected([]);
        } else if (isGroupMenuItem(selectedVal)) { // when clicking "Group Menu Item"
          setSelected(prevSelected =>
            prevSelected.filter((item) => item.type !== selectedVal).filter((item) => item.group !== selectMenuItem?.type && item.type !== 0)
          );
        } else { // when clicking "several menu item"
          setSelected(prevSelected =>
            prevSelected.filter((item) => item.type !== selectedVal && item.type !== 0).filter((item) => item.type !== selectMenuItem?.group)
          );
        }
      } else if (!isSelected) {
        if (isSelectAll) { // when clicking "All Events"
          setSelected(selectOptions);
        } else if (isGroupMenuItem(selectedVal)) { // when clicking "Group Menu Item"
          const beSelectAll = selected
          .concat(selectOptions.filter((item: Option) => item.type === selectedVal || item.type === 0))
          .concat(selectOptions.filter((item: Option) => item.group === selectedVal && !item.is_group && !isExistingorNot(item.type))).length === selectOptions.length;
          setSelected([
            ...selected,
            ...selectOptions.filter((item: Option) => item.type === selectedVal),
            ...selectOptions.filter((item: Option) => (item.group === selectedVal && !item.is_group && !isExistingorNot(item.type)) || (beSelectAll ? item.type === 0 : false)),
          ]);
        } else { // when clicking "several menu item"
          const countByGroup = selected.filter((item) => item.group === selectMenuItem?.group).length;
          const originCountByGroup = selectOptions.filter((item) => item.group === selectMenuItem?.group && !item.is_group).length;
          const beSelectAll = selected
          .concat(selectOptions.filter((item: Option) => item.type === selectedVal ||
            (originCountByGroup - countByGroup === 1 ? item.group === selectMenuItem?.group && item.is_group : false))).length === selectOptions.length - 1;
          setSelected([
            ...selected,
            ...selectOptions.filter((item: Option) => item.type === selectedVal ||
              (originCountByGroup - countByGroup === 1 ? item.type === selectMenuItem?.group && item.is_group : false) ||
              (beSelectAll ? item.type === 0 : false)),
          ]);
        }
      }
    }
  };

  return (
    <TextField
      {...props}
      size={props.size ?? 'small'}
      fullWidth
      label="Event Types"
      select
      SelectProps={{
        multiple: true,
        onClose: handleClose,
        renderValue: (selected) => {
          // Format the selected values
          const selectedValues = selected as number[];
          // Check if "All Events" is selected
          if (selectedValues.includes(0)) {
            return "All Events"; // Display only "All Events"
          } else {
            // Map selected values to their corresponding labels
            const selectedLabels = selectedValues.map((value) => {
              const option = selectOptions.find((opt) => opt.type === value && !opt.is_group);
              return option ? option.label : ""; // Return the label if found
            });
            // Join the labels and return
            return selectedLabels.filter(Boolean).join(', ');
          }
        },
      }}
      value={!getSelectTypes()?.length ? [] : getSelectTypes()}
      onChange={handleChange}
      sx={{
        '& div': {
          '& .event_checked': {
            display: 'none !important'
          }
        },
      }}
    >
      {[
        <MenuItem key="0_all" value="0">
          <Checkbox className="event_checked" checked={_.includes(getSelectTypes(), 0)}/>All Events
        </MenuItem>,
        ...eventsFilterOptions.map(({label, options, group}) => [
          <MenuItem key={label} value={`${group}`} sx={{backgroundColor: 'black !important'}}>
            <Checkbox className="event_checked" checked={_.includes(getSelectTypes(), group) || _.includes(getSelectTypes(), 0)}/>{label}
          </MenuItem>,
          options.map((option: {type: number; name: string}) => (
            <MenuItem
              key={`${option.type}-${option.name}`}
              value={`${option.type}`}
            >
              <Checkbox className="event_checked" checked={_.includes(getSelectTypes(), option.type) || _.includes(getSelectTypes(), 0)}/>{tweakLabelForMiner(option.name)}
            </MenuItem>
          )),
        ]),
      ]}
    </TextField>
  );
};
