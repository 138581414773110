import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import {IconButton, InputAdornment, TextFieldProps} from '@mui/material';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {useEffect, useMemo, useRef, useState} from 'react';

interface Props {
  label?: string;
  value?: Date | undefined;
  maxDate?: Date | undefined;
  disabled?: boolean;
  renderInput?: Omit<TextFieldProps, 'variant'>;
  onChange?: (value: Date | undefined) => any;
}

export const ShaftDateSelect = ({
  label,
  value,
  maxDate,
  disabled,
  renderInput,
  onChange,
}: Props) => {
  const date = useMemo(() => (value ? dayjs(value) : null), [value]);
  const dateRef = useRef(date);
  const [tmpDate, setTmpDate] = useState(date);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setTmpDate(date);
    dateRef.current = date;
  }, [date]);

  const [open, setOptn] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        open={open}
        value={tmpDate}
        label={label}
        disabled={disabled}
        timeSteps={{ minutes: 1 }}
        format="YYYY-MM-DD HH:mm"
        slotProps={{
          textField: {
            ...renderInput,
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                   {(tmpDate && isHovered) && (
                    <IconButton onClick={() => {
                      setTmpDate(null);
                      onChange?.(undefined);
                    }}>
                      <CloseIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={() => setOptn(true)}>
                    <CalendarMonthIcon />
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: {
                readOnly: true,
              },
              onClick: () => setOptn(true),
              onMouseEnter: () => setIsHovered(true),
              onMouseLeave: () => {
                setIsHovered(false);
              }
            },
          },
        }}
        maxDate={maxDate ? dayjs(maxDate) : null}
        onChange={(v) => {
          setTmpDate(v ?? null);
        }}
        onAccept={(v) => {
          onChange?.(v?.toDate() ?? undefined);
          setTmpDate(v ?? null);
        }}
        onClose={() => {
          setOptn(false);
        }}
      />
    </LocalizationProvider>
  );
};
