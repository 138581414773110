import {Button, Menu} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {EventSummaryProductValue} from '../../../interfaces/Event';
import EventSummaryList from '../EventSummaryList';
import { Product } from './EventStatAckButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  product?: EventSummaryProductValue;
  individualProduct?: Product[];
  eventDescription?: string;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const SummaryEventButton = <T extends ComponentType = typeof Button>({
  product,
  individualProduct,
  eventDescription,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(null)}
      >
        <EventSummaryList
          eventDescription={eventDescription}
          productValue={product}
          individualProductValue={individualProduct}
          onDone={() => {
            onDone?.();
            setMenuAnchorEl(null);
          }}
        />
      </Menu>
    </>
  );
};

export default SummaryEventButton;
